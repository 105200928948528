import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "modules/events/actionCreator";
import moment from "moment";

import Loading from "modules/common/components/loading";

import { EventFeedFilters, EventFeedItem } from "../../models";
import EventView from "../event-views/eventView";

import Filters from "./components/filters/Filters";
import VisualCard from "./components/layouts/visualCard/VisualCard";
import Views from "./components/views/Views";
import ViewOptions from "./consts/viewOptions";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";


import "../../styles/eventFeed.sass";
import { DialogContentView } from "modules/common/components/dialogs/dialogContentView";


class EventFeed extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            selectedEventId: props.selectedEventId || "",
            pageNumber: 1
        };
    }

    public componentWillMount() {
        if (this.props.shouldFetch) {
            this.fetchFeed(ViewOptions[this.props.selectedView].filters, false, ViewOptions[this.props.selectedView].sortAscending, this.state.pageNumber);
        }
    }

    public componentDidMount() {
        moment.locale("en");
    }

    public componentDidUpdate(prevProps: PropsWithRedux) {
        if (this.props.shouldFetch && (this.props.shouldFetch !== prevProps.shouldFetch)) {
            this.fetchFeed(ViewOptions[this.props.selectedView].filters, false, ViewOptions[this.props.selectedView].sortAscending, 1);
        }
    }

    public render() {
        const { eventsFeed } = this.props;

        if (this.props.shouldFetch || !this.props.currentUser.userId)
            return <Loading />;

        return (
            <React.Fragment>
                <Filters
                    onChangeFilters={this.onChangeFilters}
                    onClearFilters={this.onClearFilters}
                    onCloseFilters={this.onCloseFilters}
                />
                <div>
                    <Views onChangeView={this.changeView} />
                    {(!eventsFeed.length && this.props.fetching)
                        ? (
                            <Loading />
                        )
                        : (
                            <React.Fragment>
                                {this.props.filtersApplied &&
                                    <Typography variant="h2" className="feed-results">Results</Typography>
                                }
                                {eventsFeed.length === 0
                                    ? <div className="no-feed">No events were found</div>
                                    : this.getLayout()
                                }
                                {this.props.canLoadMore && (
                                    this.props.fetching
                                        ? <Loading />
                                        : <div className="load-more">
                                            <Button variant="text" color="primary" onClick={this.continueFetching}>Load more events</Button>
                                        </div>
                                )}
                            </React.Fragment>
                        )
                    }
                    <DialogContentView id="view-event-dialog" open={!!this.state.selectedEventId} classes={{ paper: "dialog-content" }} onClose={this.unselectEvent}>
                        <EventView eventId={this.state.selectedEventId} onClose={this.unselectEvent} />
                    </DialogContentView>
                </div>
            </React.Fragment>
        );
    }


    private onChangeFilters = (filters: Partial<EventFeedFilters>) => {
        const updatedFilters = { ...this.props.filters, ...filters };
        this.props.clearEventFeed();
        this.props.setEventFeedFilters(updatedFilters);
        this.setState({ pageNumber: 1 });
        this.fetchFeed({ ...ViewOptions[this.props.selectedView].filters, ...updatedFilters }, true, ViewOptions[this.props.selectedView].sortAscending, 1);
    }

    private onClearFilters = () => {
        this.props.clearEventFeedFilters();
        this.props.clearEventFeed();
    }

    private onCloseFilters = () => {
        this.props.setShowEventFeedFilters(false);
    }

    private changeView = (view: string) => {
        if (view !== this.props.selectedView && !this.props.fetching) {
            this.props.setEventFeedView(view);
            this.props.clearEventFeed();
            this.setState({ pageNumber: 1 });
            this.fetchFeed({ ...ViewOptions[view].filters, ...this.props.filters }, this.props.filtersApplied, ViewOptions[view].sortAscending, 1);
        }
    }


    private continueFetching = () => {
        const { filters } = this.props;

        const sortAscending: boolean = ViewOptions[this.props.selectedView].sortAscending;

        const updatedFilters = {
            ...filters,
            ...ViewOptions[this.props.selectedView].filters,
        };
        const newPage = this.state.pageNumber + 1;
        this.fetchFeed(updatedFilters, this.props.filtersApplied, sortAscending, newPage);
        this.setState({ pageNumber: newPage });
    }


    private fetchFeed = (filters: Partial<EventFeedFilters>, filtersApplied: boolean, sortAscending: boolean, pageNumber: number) => {
        this.props.getEventFeed(filters, filtersApplied, sortAscending, pageNumber);
    }


    private getLayout(): JSX.Element {
        return <VisualCard onEventSelected={this.selectEvent} />;
    }

    private selectEvent = (event: EventFeedItem) => {
        this.setState({ selectedEventId: event.id });
    }

    private unselectEvent = () => {
        this.setState({ selectedEventId: "" });
    }
}


interface ComponentProps {
    selectedEventId?: string;
}

interface ComponentState {
    selectedEventId: string;
    pageNumber: number;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        eventsFeed: state.events.eventsFeed.eventsFeed,
        canLoadMore: state.events.eventsFeed.canLoadMore,
        fetching: state.events.eventsFeed.fetching,
        filters: state.events.eventsFeed.filters,
        filtersApplied: state.events.eventsFeed.filtersApplied,
        selectedView: state.events.eventsFeed.selectedView,
        shouldFetch: state.events.eventsFeed.shouldFetch,
        currentUser: state.settings.currentUser
    }),
    {
        clearEventFeed: actions.clearEventFeed,
        clearEventFeedFilters: actions.clearEventFeedFilters,
        getEventFeed: actions.getEventFeed,
        setEventFeedFilters: actions.setEventFeedFilters,
        setEventFeedView: actions.setEventFeedView,
        setShowEventFeedFilters: actions.setShowEventFeedFilters
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(EventFeed);
