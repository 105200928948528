import React, { useEffect, useState } from "react";
import { Input, InputBaseComponentProps, IconButton, SvgIcon } from "@mui/material";

import ConditionalWrapper from "../../conditionalWrapper";
import { useUndoableState } from "modules/common/hooks/useUndoableState";
import usePrevious from "modules/common/hooks/usePrevious";

import "../styles/filledInput.sass";

interface IFilledInputProps {
    inputProps?: InputBaseComponentProps; // MUI input props - these will overwrite defaults
    inputStyle?: React.CSSProperties; // override styling of MUI Input component
    label: string;
    value: string;
    required?: boolean;
    characterCount?: boolean;
    maxCount?: number;
    labelOnly?: boolean;
    controlled?: boolean;
    onUndo?: (newValue: string | undefined) => void;
    refreshable?: boolean; // flag whether to re-render input on "value" prop change
    inputContainerStyle?: React.CSSProperties;
    inputDivStyle?: React.CSSProperties;
    inputLabelStyle?: React.CSSProperties;
    outlinedInput?: boolean;
    errorHelperText?: string;
    inputEndAdornmentText?: string;
    inputDivContainerStyle?: React.CSSProperties;
}

/**
 * A android looking input field with a label
 * - use inputProps to overwrite any defaults on the MUI input component
 */
const FilledInput: React.FunctionComponent<IFilledInputProps> = ({
    label,
    required = false,
    value = "",
    characterCount = false,
    maxCount,
    labelOnly = false,
    controlled = false,
    inputProps = {},
    refreshable = false,
    onUndo,
    inputContainerStyle,
    inputDivStyle,
    inputLabelStyle,
    outlinedInput = false,
    errorHelperText = "",
    inputEndAdornmentText,
    inputDivContainerStyle,
    inputStyle = undefined,
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const {
        value: internalValue,
        setValue: setInternalValue,
        undoValue,
        undoable
    } = useUndoableState<string | undefined>(value);

    const prevValue = usePrevious<string>(value);

    /**
     * Put any prop updates in state
     */
    useEffect(() => {
        let newValue = value;
        if (maxCount && newValue.length > maxCount) {
            newValue = newValue.substring(0, maxCount);
        }

        // only transfer value if "value" prop has changed and it is new
        if (prevValue !== newValue && newValue !== internalValue)
            setInternalValue(newValue);
    }, [value, maxCount, setInternalValue, prevValue, internalValue]);

    /**
     * Intercept event and apply any max count logic
     */
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.target.value;
        let effectiveNewValue = maxCount && newValue.length > maxCount
            ? internalValue // keep value at max count
            : newValue;

        if (controlled) {
            if (!inputProps || !inputProps.onChange)
                throw new Error("filledInput requires inputProps.onChange if controlled");

            inputProps.onChange(event);

            return;
        }

        setInternalValue(effectiveNewValue);
    }

    // only add error class when focused
    const getMaxCountErrorClass = (): string => {
        return isFocused && maxCount && internalValue?.length === maxCount ? "error" : "";
    }

    const getInputErrorClass = (): string => {
        return outlinedInput && errorHelperText ? "error" : getMaxCountErrorClass();
    }

    const onUndoInternal = () => {
        // using return value here so that we send correct new value to parent and not the pre-state change one
        let newValue = undoValue();

        if (onUndo)
            onUndo(newValue);
    }

    return (
        <div className="filled-input-container" style={inputContainerStyle}>
            <div className="filled-input-label-container">
                <label className={`filled-input-label ${required ? "required" : ""}`} htmlFor={inputProps?.id} style={{...inputLabelStyle}}>
                    {label}
                </label>
                {onUndo &&
                    <IconButton onClick={onUndoInternal} disabled={!undoable}>
                        <SvgIcon viewBox="0 0 40 40" style={{ fontSize: 16 }}>
                            <path
                                fill={undoable ? "#3b78ab" : "#d7d7d7"}
                                d="M14 38v-3h14.45q3.5 0 6.025-2.325Q37 30.35 37 26.9t-2.525-5.775Q31.95 18.8 28.45 18.8H13.7l5.7 5.7-2.1 2.1L8 17.3 17.3 8l2.1 2.1-5.7 5.7h14.7q4.75 0 8.175 3.2Q40 22.2 40 26.9t-3.425 7.9Q33.15 38 28.4 38Z" />
                        </SvgIcon>
                    </IconButton>}
            </div>
            {!labelOnly && (
                <ConditionalWrapper condition={refreshable} wrapper={(children) => <div key={value}>{children}</div>}>
                    <div style={inputDivContainerStyle}>
                        <div style={inputDivStyle}>
                            <Input
                                className={`filled-input${outlinedInput ? '-outlined' : ''} ${getInputErrorClass()}`}
                                multiline
                                fullWidth
                                disableUnderline
                                value={(controlled ? value : internalValue) || ""}
                                onChange={onChange}
                                inputProps={{
                                    ...inputProps,
                                    onBlur: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                        setIsFocused(false);

                                        if (inputProps && inputProps.onBlur)
                                            inputProps.onBlur(event);
                                    },
                                    onFocus: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                        setIsFocused(true);

                                        if (inputProps && inputProps.onFocus)
                                            inputProps.onFocus(event);
                                    },
                                }}
                                style={inputStyle}
                            />
                            <div className="filled-input-helper-container">
                                <span className="filled-input-helper-text">
                                    {errorHelperText}
                                </span>
                                {characterCount && maxCount &&
                                    <span className={`filled-input-char-count ${getMaxCountErrorClass()}`}>{internalValue?.length}/{maxCount}</span>
                                }
                            </div>
                        </div>
                        {inputEndAdornmentText && <span className="filled-input-end-adorment-text">{inputEndAdornmentText}</span>}
                    </div>
                </ConditionalWrapper>
            )}
        </div>
    );
}

export default FilledInput;
