import React from "react";

import { connect, ConnectedProps } from "react-redux";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { appInsightsReactPlugin } from "appInsights";
import { withRouter, RouteComponentProps } from "react-router";

import AdminPortalSplashScreen from "modules/common/components/splash-screens/adminPortalSplashScreen";
import Header from "../../../modules/common/components/header/Header";
import { GlobalApplicationState } from "../../../globalApplicationState";
import CookieBanner from "../components/CookieBanner";
import Menu from "./components/Menu";
import { COMMON_COOKIE_KEYS } from "../../../utils/cookie";
import useDrawerExpanded from "modules/common/hooks/useDrawerExpanded";

import "./index.sass";

const drawerWidth = 62;
const expandedDrawerWidth = 254;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            display: "flex",
            maxHeight: `calc(100vh - 48px)`,
            minHeight: `calc(100vh - 48px)`,
            overflow: "hidden",
            position: "relative"
        },
        drawer: {
            flexShrink: 0,
            position: "relative",
            transition: "width linear 0.2s",
            width: drawerWidth,
            "&.expanded": {
                width: expandedDrawerWidth,
                "& $drawerPaper": {
                    width: expandedDrawerWidth
                }
            }
        },
        drawerPaper: {
            backgroundColor: theme.palette.background.default,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "hidden",
            position: "absolute",
            transition: "width linear 0.2s",
            width: drawerWidth,
            zIndex: 1
        },
        content: {
            flexGrow: 1,
            overflow: "auto",
            padding: "12px 0",
            position: "relative",
            zIndex: 0
        }
    })
);

const Layout: React.FunctionComponent<PropsWithRedux> = props => {
    const { location, tenant, displayName } = props;
    const classes = useStyles();
    const isFullEditScreen = location.pathname.indexOf("/admin/portalPages/edit/") !== -1;
    const { drawerExpanded, toggleDrawerExpanded } = useDrawerExpanded(COMMON_COOKIE_KEYS.ADMIN_DRAWER_EXPANDED);

    const showBarAndMenu = !location.pathname.includes("/posts/edit");

    return (
        <div id="app-base" className={`${!showBarAndMenu ? "authoring" : ""}`}>
            {showBarAndMenu && <Header headerStyle={props.adminLayout.headerStyle} displayName={displayName} onClickMenu={toggleDrawerExpanded} headerBackgroundColor="#dde1e5" wordMark showRole />}
            <div id="admin-main-container" className={classes.main}>
                {!isFullEditScreen && showBarAndMenu &&
                    <nav className={`${classes.drawer} ${drawerExpanded ? "expanded" : ""}`} style={{ zIndex: 0 }}>
                        <Menu
                            expand={drawerExpanded}
                            locationPathName={location.pathname}
                            setExpand={toggleDrawerExpanded}
                            tenant={tenant.id}
                            drawerPaperClassName={classes.drawerPaper}
                        />
                    </nav>
                }
                <main
                    id="admin-main"
                    style={{ ...props.adminLayout.mainStyle, padding: isFullEditScreen ? 0 : undefined }}
                    className={classes.content}
                >
                    {props.children}
                </main>
            </div>
            <CookieBanner />
            <div id="pause-window" className="pause-window-execution" />
        </div>
    );
}

class AdminAppLayout extends React.Component<PropsWithRedux, ComponentState> {
    constructor(props: PropsWithRedux) {
        super(props);

        this.state = {
            displaySplashScreen: false
        };
    }

    public componentDidMount() {
        document.title = "Admin Portal";

        let favicon: any = document.querySelector("link[rel~='icon']");
        if (!!favicon)
            favicon.href = "/favicon-admin.png";

        if (!!this.props.history.location.state && !!this.props.history.location.state.prevPath && !this.props.history.location.state.prevPath.includes("/admin/"))
            this.setState({ displaySplashScreen: true });
    }

    public render() {
        return (
            <AdminPortalSplashScreen displaySplashScreen={this.state.displaySplashScreen}>
                <Layout {...this.props} />
            </AdminPortalSplashScreen>
        );
    }
}

interface ComponentProps {
    userIsInternalOnly?: boolean;
}

interface ComponentState {
    displaySplashScreen: boolean;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        tenant: state.tenant,
        displayName: state.settings.tenantSettings.displayName,
        adminLayout: state.adminLayout
    })
);
type PropsWithRedux = ConnectedProps<typeof connector> & RouteComponentProps<{}>;

export default withRouter(connector(withAITracking(appInsightsReactPlugin, AdminAppLayout)));
