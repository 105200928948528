import * as head from "modules/head";
import * as activity from "modules/activity";
import * as audiences from "modules/audiences";
import { reducer as config } from "modules/config/reducer";
import * as documents from "modules/documents";
import * as events from "modules/events";
import * as gallery from "modules/gallery";
import * as insights from "modules/insights";
import { reducer as localization } from "modules/localization/reducer";
import * as portalPages from "modules/portalPages";
import * as posts from "modules/posts";
import * as profile from "modules/profile";
import * as resources from "modules/resources";
import * as settings from "modules/settings";
import * as surveys from "modules/surveys";
import * as tenant from "modules/tenant";
import * as users from "modules/users";
import * as messaging from "modules/messaging";
import * as categoryTags from "modules/categorytags";
import * as adminLayout from "modules/adminLayout";
import * as userPortalLayout from "modules/userPortalLayout";

import { ReducersMapObject } from "redux";
import { GlobalApplicationState } from "globalApplicationState";

export const reducers: ReducersMapObject<GlobalApplicationState, any> = {
    activity: activity.reducers.activity,
    audiences: audiences.reducers.audiences,
    config,
    documents: documents.reducers.documents,
    events: events.reducers.events,
    head: head.reducers.head,
    insights: insights.reducers.insights,
    gallery: gallery.reducers.gallery,
    localization: localization,
    portalPages: portalPages.reducers.portalPages,
    posts: posts.reducers.posts,
    profile: profile.reducers.profile,
    resources: resources.reducers.resources,
    settings: settings.reducers.settings,
    surveys: surveys.reducers.surveys,
    tenant: tenant.reducers.tenant,
    users: users.reducers.users,
    messaging: messaging.reducers.messaging,
    categoryTags: categoryTags.reducers.categoryTags,
    adminLayout: adminLayout.reducers.adminLayoutReducer,
    userPortalLayout: userPortalLayout.reducers.userPortalLayoutReducer
};

export default reducers;
