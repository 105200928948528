import * as React from "react";

import { EventType } from "../../models";


const Attendance: React.FunctionComponent<ComponentProps> = props => {
  if (props.eventType === "informational")
    return <React.Fragment></React.Fragment>;
  
  const attendanceText = (): JSX.Element => {
    if (props.isCurrentUserAttendingInPerson)
      return <div className="attending-in-person">Attending in-person</div>;
    else if (props.isCurrentUserAttendingOnline)
      return <div className="attending-online">Attending online</div>;
    else if (props.isCurrentUserNotAttending)
      return <div className="not-attending">Not attending</div>;
    else if (props.isCurrentUserOnWaitlist)
      return <div className="waitlisted">Waitlisted</div>;
    else if (props.eventType === "mandatory")
      return <div className="response-required">Response required</div>;

    return <div className="not-responded-yet">Not responded yet</div>;
  }

  return (
    <div className="attendance">
      {attendanceText()}
    </div>
  );
}

interface ComponentProps {
  eventType: EventType;
  isCurrentUserAttendingInPerson: boolean;
  isCurrentUserAttendingOnline: boolean;
  isCurrentUserNotAttending: boolean;
  isCurrentUserOnWaitlist: boolean;
}

export default Attendance;