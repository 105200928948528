import React from "react";

import { PortalPage } from "../../models";

import TinaEditor from "../../tinacms/editor";
import TinaCMSWrapper from "../../tinacms/setup";

const PortalPagesEditor: React.FunctionComponent<ComponentProps> = props => {
  return (
    <TinaCMSWrapper>
      <TinaEditor page={props.portalPage} />
    </TinaCMSWrapper>
  );
}

interface ComponentProps {
  portalPage: PortalPage;
  onChangePortalPage: (page: Partial<PortalPage>) => void;
}

export default PortalPagesEditor;