import * as React from "react";
import { ChromePicker } from "react-color";

const ColorOption: React.FunctionComponent<ComponentProps> = props => {

  const [colorText, setColorText] = React.useState(props.colorSelected ?? "");
  const [previousColor, setPreviousColor] = React.useState("");

  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (!!ref.current && !ref.current.contains(event.target)) {
          props.setVisible(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const ref = React.useRef(null);
  useOutsideAlerter(ref);

  React.useEffect(() => {
    let currentColor = props.colorSelected;
    if(props.colorSelected !== previousColor)
      setColorText(currentColor);
      setPreviousColor(currentColor);
  }, [props.colorSelected, previousColor])

  //Only update text if it's a full hex code (#ZZZZZZ)
  const updateText = (toSet: string) => {
    setColorText(toSet);

    if(toSet.length === 7 && toSet.charAt(0) === '#')
      props.changeText(toSet);
  }

  let colorSelected = props.colorSelected;

  return (
      <div ref={ref}>
          <input
              onClick={(e) => props.setVisible(!props.isColorPickerVisible)}
              onChange={(e) => updateText(e.target.value.toUpperCase())}
              value={colorText}
              style={{
                  borderRightWidth: "35px",
                  borderRightColor: colorSelected,
                  borderLeftColor: "#DDE1E5",
                  borderTopColor: "#DDE1E5",
                  borderBottomColor: "#DDE1E5",
                  borderRadius: "4px",
                  width: "80%",
                  height: "30px",
                  textAlign: "center",
                  borderStyle: "solid",
                  outlineColor: "#3B78AB",
                  textTransform: "uppercase",
                  boxShadow: "0px 0px 0px 1px #DDE1E5"
              }}
          />
          {props.isColorPickerVisible &&
          <div style={{zIndex: 1, position: "relative"}}>
            <ChromePicker
                color={colorSelected}
                onChangeComplete={(e) => props.setColor(e.hex.toUpperCase())}
            >
            </ChromePicker>
          </div>
          }
      </div>
  );
}

interface ComponentProps {
    isColorPickerVisible: boolean;
    colorSelected: string;
    setColor: (color: string) => void;
    setVisible: (toSet: boolean) => void;
    changeText: (toSet: string) => void;
}

export default ColorOption;