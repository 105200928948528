import React from "react";
import { Action } from "redux";
import { ActionCreator } from "typedActions";

export const SET_MAIN_STYLE = "SET_MAIN_STYLE";
export interface ISetMainStyle extends Action {
    newStyle?: React.CSSProperties;
}
export const CreateSetMainStyle = ActionCreator<ISetMainStyle>(SET_MAIN_STYLE);

export const SET_HEADER_STYLE = "SET_HEADER_STYLE";
export interface ISetHeaderStyle extends  Action {
    newStyle?: React.CSSProperties;
}
export const CreateSetHeaderStyle = ActionCreator<ISetHeaderStyle>(SET_HEADER_STYLE);
