import React from "react"

import Paper from "@mui/material/Paper";

import { Theme } from "@mui/material/styles";


import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      boxSizing: "border-box",
      flexShrink: 1,
      flexGrow: 0,
      maxHeight: "100%",
      overflowY: "auto",
      "&.add-content-padding": {
        padding: 20
      },
      "&.add-content-padding-10": {
        padding: 10
      },
      "&.add-margin-top": {
        marginTop: 15
      },
      "&.full-height": {
        height: '100%'
      }
    }
  })
);


const MainContent: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles();

  return (
    <Paper 
        id={props.id}
        className={`${classes.paper} ${props.addPadding ? "add-content-padding" : ""} ${props.addPadding10 ? "add-content-padding-10" : ""} ${props.addMarginTop ? "add-margin-top": ""} ${props.fullHeight ? "full-height" : ""}`}
    >
      {props.children}
    </Paper>
  );
}

interface ComponentProps {
  id?: string;
  addPadding?: boolean; 
  addPadding10?: boolean; 
  addMarginTop?: boolean;
  fullHeight?: boolean;
}

export default MainContent;