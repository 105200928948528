import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { PostViewStats } from "modules/posts/models";

import ShareButton from "modules/common/components/buttons/shareButton";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";


import "./typeBanner.sass";


const icoComplied: string = "/images/icons/complied_ico.svg";
const icoMandatory: string = "/images/icons/mandatory_ico.svg";
const icoNotComplied: string = "/images/icons/not_complied_ico.svg";
const icoPublic: string = "/images/icons/public_ico.svg";
const icoRestricted: string = "/images/icons/restricted_ico.svg";


interface IBanner {
  color: string;
  icon: string;
  text: string;
  description: string;
  urlDescription?: string;
}

type BannerTypes = "complied" | "mandatory" | "notComplied" | "public" | "restricted";

type Banners = { [bannerType in BannerTypes]: IBanner; };

const BannerText: React.FunctionComponent<{full?: boolean, expanded: boolean, onClick: (e: any) => void, style?: React.CSSProperties}> = (props) => {
  return (
    <div onClick={props.full ? undefined : props.onClick} className={`text ${props.expanded ? "expand" : ""}`}>
      <div style={props.style}>
        {props.children}
        {props.full &&
          <CloseIcon onClick={props.onClick} className="clear-icon" />
        }
      </div>
    </div>
  );
};

const ExpandIcon: React.FunctionComponent<{full?: boolean, expanded: boolean, onClick: (e: any) => void, style?: React.CSSProperties}> = (props) => {
  return (
    <div onClick={props.onClick} style={props.style} className={`icon ${props.expanded ? "expanded" : "collapsed"}`}>
      {props.children}
      {props.full &&
        <ChevronRightIcon className="expand-icon" />
      }
    </div>
  );
};

const TypeBanner: React.FunctionComponent<{className?: string}> = (props) => {
  return (
    <div className={`type-banner ${props.className || ""}`}>
      {props.children}
    </div>
  );
};


const Banner: React.FunctionComponent<{banner: IBanner, full?: boolean, description?: JSX.Element, expanded: boolean, onClick: (e: any) => void, className?: string}> = (props) => {
  const { banner, full, description, expanded, onClick, className } = props;

  return (
    <TypeBanner className={className || ""}>
      <ExpandIcon full={full} expanded={expanded} onClick={onClick} style={{ backgroundColor: banner.color }}>
        <img src={banner.icon} alt="post type banner" />
      </ExpandIcon>
      <BannerText full={full} expanded={expanded} onClick={onClick} style={{ backgroundColor: banner.color }}>
        <div>
          {!full
            ? banner.text
            : description || banner.description
          }
        </div>
      </BannerText>
    </TypeBanner>
  );
}


class PostTypeBanner extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      expandPublic: false,
      expandRestricted: false,
      expandType: true
    };
  }

  public componentDidMount() {
    this.onSetBannerExpansions();
  }

  public render() {
    if (!this.props.clientSettings)
      return (null);

    const { clientSettings } = this.props;
    const { specialtyPostColors } = clientSettings;

    const banners: Banners = {
      complied: {
        color: (specialtyPostColors && specialtyPostColors.complianceChecked) || "#05b34b",
        icon: icoComplied,
        text: this.onGetTranslatedText("compliedPost") || "Complied",
        description: this.onGetTranslatedText("compliedBannerDescription") || "You have already read and complied to this post."
      },
      mandatory: {
        color: (specialtyPostColors && specialtyPostColors.mandatory) || "#004e79",
        icon: icoMandatory,
        text: this.onGetTranslatedText("mandatoryPost") || "Mandatory",
        description: this.onGetTranslatedText("mandatoryBannerDescription") || "You are required to read this post."
      },
      notComplied: {
        color: (specialtyPostColors && specialtyPostColors.compliance) || "#faaf18",
        icon: icoNotComplied,
        text: this.onGetTranslatedText("compliancePost") || "Compliance",
        description: this.onGetTranslatedText("complianceBannerDescription") || "If you agree with the content of this post, comply using the checkbox down below."
      },
      public: {
        color: (specialtyPostColors && specialtyPostColors.public) || "#ffffff",
        icon: icoPublic,
        text: this.onGetTranslatedText("publicPost") || "Public",
        description: this.onGetTranslatedText("publicBannerDescription") || "This is a public post.",
        urlDescription: this.onGetTranslatedText("publicBannerDescriptionShareTitle") || "Share it through here"
      },
      restricted: {
        color: (specialtyPostColors && specialtyPostColors.restricted) || "#b72026",
        icon: icoRestricted,
        text: this.onGetTranslatedText("restrictedPost") || "Restricted",
        description: this.onGetTranslatedText("restrictedBannerDescription") || "This post is exclusive to specific audiences."
      }
    };

    return (
      <div style={{ position: "absolute" }} className={`type-banners ${this.props.full ? "full" : ""}`}>
        {this._typeBanner(banners)}
        {this._restrictedBanner(banners.restricted)}
        {this._publicBanner(banners.public)}
      </div>
    );
  }

  private _publicBanner = (banner: IBanner) => {
    // don't show share link if not public or if is not published
    if (this.props.postType !== "public" || this.props.status !== "published")
      return (null);
    
    return (
      <Banner
        banner={banner}
        full={this.props.full}
        description={
          <React.Fragment>
            <span>{banner.description} </span>
            <ShareButton articleId={this.props.id} articleTitle={this.props.title} articleType="post" isPublic={this.props.postType === "public"} linkText={banner.urlDescription} />
            <span>.</span>
          </React.Fragment>
        }
        expanded={this.state.expandPublic}
        onClick={this.onToggleExpandPublic}
        className="public" />
    );
  }

  private _restrictedBanner = (banner: IBanner) => {
    if (!this.props.isRestricted || !this.props.clientSettings.bannerVisibility.showRestrictedBanner) {
      return (null);
    }

    return (
      <Banner
        banner={banner}
        full={this.props.full}
        expanded={this.state.expandRestricted}
        onClick={this.onToggleExpandRestricted}
        className="restricted" />
    );
  }

  private _typeBanner = (banners: Banners) => {
    const { postType, stats, full, currentUserHasComplied, hideCompliance } = this.props;

    if (postType === "standard" || postType === "public")
      return (null);

    let banner = banners.mandatory;
    if (postType === "compliance")  {
        if (stats) {
          banner = !!stats && !!stats.complianceTime ? banners.complied : banners.notComplied
        }
        else {
          banner = currentUserHasComplied ? banners.complied : banners.notComplied;
        }
    }

    if (hideCompliance) return null

    return (
      <Banner
        banner={banner}
        full={full}
        expanded={this.state.expandType}
        onClick={this.onToggleExpandType} />
    );
  }

  private onGetTranslatedText = (field: string, defaultLCID: string = "en-us") => {
    const { clientSettings, lcid } = this.props;
    const { translatedContent } = clientSettings;

    if (!translatedContent || (!translatedContent[lcid] && !translatedContent[defaultLCID]))
      return "";
    
    return translatedContent[lcid] ? translatedContent[lcid][field] : translatedContent[defaultLCID][field];
  }

  private onSetBannerExpansions = () => {
    const expandType: boolean = this.props.postType === "compliance" || this.props.postType === "mandatory";
    const expandRestricted: boolean = !expandType && this.props.clientSettings.bannerVisibility.showRestrictedBanner && !!this.props.isRestricted;
    const expandPublic: boolean = !expandType && !expandRestricted && this.props.postType === "public";

    this.setState({
      expandPublic,
      expandRestricted,
      expandType
    });
  }

  private onToggleExpandPublic = (e: any) => {
    e.stopPropagation();
    if (this.state.expandPublic)
      this.setState({ expandPublic: false });
    else
      this.setState({
        expandPublic: true,
        expandRestricted: false,
        expandType: false
      });
  }

  private onToggleExpandRestricted = (e: any) => {
    e.stopPropagation();
    if (this.state.expandRestricted)
      this.setState({ expandRestricted: false });
    else
      this.setState({
        expandPublic: false,
        expandRestricted: true,
        expandType: false
      });
  }

  private onToggleExpandType = (e: any) => {
    e.stopPropagation();
    if (this.state.expandType)
      this.setState({ expandType: false });
    else
      this.setState({
        expandPublic: false,
        expandRestricted: false,
        expandType: true
      });
  }
}


interface ComponentProps {
  lcid: string;
  postType: string;
  full?: boolean;
  id: string;
  isRestricted?: boolean;
  stats?: PostViewStats;
  title: string;
  currentUserHasComplied?: boolean
  hideCompliance?: boolean
  status: string;
}

interface ComponentState {
  expandPublic: boolean;
  expandRestricted: boolean;
  expandType: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    clientSettings: state.settings.clientSettings,
    lcidMappings: state.resources.lcidMappings
  }),
  {
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(PostTypeBanner);