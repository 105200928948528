import React from "react";

import { Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import Callout from "../../callout";
import { KeyValue } from "modules/localization/models";

import "../styles/calloutMultiselect.sass";

interface ICalloutMultiselectProps {
    items: KeyValue[];
    open: boolean;
    setOpen: (toSet: boolean) => void;
    anchorEl: HTMLElement | null;
    activeValue?: string;
    defaultValue?: string;
    selectedValues?: string[];
    onSelectItem: (item: KeyValue) => void;
    onAddItem: (item: KeyValue) => void; // add button click
    onRemoveItem: (item: KeyValue) => void; // list item click
}

const CalloutMultiselect: React.FunctionComponent<ICalloutMultiselectProps> = ({
    items,
    open,
    anchorEl,
    selectedValues = [],
    activeValue = "",
    defaultValue = "",
    onAddItem,
    onRemoveItem,
    onSelectItem,
    setOpen
}) => {
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('md'));

    const getActions = (isDefault: boolean, isSelected: boolean, item: KeyValue): JSX.Element => {
        if (isDefault) return <></>;

        let element: JSX.Element;
        if (isSelected)
            element = <IconButton
                size="small"
                onClick={() => {
                    onRemoveItem(item);
                }}
                className="action"
            >
                <ClearIcon htmlColor="#b72026"/>
            </IconButton>;
        else
            element = <Button
                variant="text"
                color="primary"
                onClick={() => {
                    onAddItem(item);
                }}
                className="action"
            >
                Add
            </Button>

        return element;
    }

    return (
        <Callout
            contentClassName="callout-multiselect-content"
            anchorEl={anchorEl}
            open={open}
            setOpen={setOpen}
            arrowStyle={{ display: "none" }}
            popoverProps={{
                anchorOrigin: isSmallAndSmaller
                    ? { vertical: "bottom", horizontal: "center" }
                    : { vertical: -15, horizontal: -117 },
            }}
        >
            {items.map((item: KeyValue) => {
                const isActive = item.key === activeValue;
                const isDefault = item.key === defaultValue;
                const isSelected = selectedValues.find((selectedItem) => selectedItem === item.key) !== undefined;

                return (
                    <div key={item.key} className={`callout-multiselect-item ${isActive ? "selected" : ""}`}>
                        <Button style={{ textTransform: "none" }} className={`main-item ${isActive ? "selected" : ""}`} onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            onSelectItem(item);
                        }}>
                            {item.value}
                        </Button>
                        {isDefault &&
                            <span className="callout-multiselect-default-item action">Default</span>}
                        {getActions(isDefault, isSelected, item)}
                    </div>
                );
            })}
        </Callout>
    );
}

export default CalloutMultiselect;
