import React from "react";

import {
    Box,
    Breakpoint,
    Button,
    ButtonProps,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Stack,
    FormControlLabel
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IConfirmDialogProps {
    title: string;
    open: boolean;
    confirmLabel?: string;
    denyLabel: string;
    optionLabel?: string;
    checkboxLabel?: string;
    confirmButtonProps?: ButtonProps;
    denyButtonProps?: ButtonProps;
    optionButtonProps?: ButtonProps;
    headerStyle?: React.CSSProperties;
    dialogMaxWidth?: false | Breakpoint;
    dialogButtonPadding?: boolean;
    onConfirm?: () => void;
    onDeny: () => void;
    onClose: () => void;
    onOption?: () => void;
    onCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const ConfirmDialog: React.FunctionComponent<IConfirmDialogProps> = ({
    title,
    open,
    confirmLabel,
    denyLabel,
    optionLabel,
    checkboxLabel,
    children,
    confirmButtonProps,
    optionButtonProps,
    headerStyle,
    denyButtonProps,
    dialogMaxWidth,
    onClose,
    onConfirm,
    onDeny,
    onOption,
    onCheckboxChange,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth={dialogMaxWidth ?? "sm"}
        >
            <div style={{ padding: "16px 24px", display: "flex", alignItems: "center", justifyContent: "space-between", ...headerStyle }}>
                <span style={{ fontSize: 18, fontWeight: 500 }}>{title}</span>
                <IconButton onClick={onClose} size="large" edge="end">
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogContent style={{paddingTop: 8, paddingBottom: 8}}>
                {children}
            </DialogContent>
            <DialogActions>
                <Stack
                    flexDirection='row'
                    justifyContent="space-between"
                    gap={2}
                    width='100%'
                    padding="8px 16px"
                >
                    <Box
                        sx={{
                            flexShrink: 1,
                            alignSelf:'flex-start',
                            marginLeft: '10px'
                        }}
                    >
                        {
                            checkboxLabel && onCheckboxChange &&
                            <FormControlLabel control={<Checkbox onChange={onCheckboxChange} edge="start"/>} label={checkboxLabel}/>
                        }
                    </Box>
                    <Stack
                        gap={2}
                        flexDirection='row'
                        sx={{
                            flexShrink: 0,
                            alignSelf:'flex-end'
                        }}
                    >
                        <Button
                            onClick={onDeny}
                            variant="text"
                            color="primary"
                            {...denyButtonProps}
                        >
                            {denyLabel}
                        </Button>
                        {
                            optionLabel && 
                            <Button
                                onClick={onOption}
                                variant="text"
                                color="primary"
                                {...optionButtonProps}
                            >
                                {optionLabel}
                            </Button>
                        }
                        {
                            confirmLabel && 
                            <Button
                                onClick={onConfirm}
                                variant="contained"
                                color="primary"
                                {...confirmButtonProps}
                            >
                                {confirmLabel}
                            </Button>
                        }
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog
