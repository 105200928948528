import React from "react";

import Container from "@mui/material/Container";

import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    basePage: {
      display: "flex",
      flexDirection: "column",
      height: "100%"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%"
    }
  })
);


const BasePage: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.basePage}>
      <Container maxWidth={props.fullWidth ? "xl" : "lg"} className={classes.container}> 
        <React.Fragment>
          {props.children}
        </React.Fragment>
      </Container>
    </div>
  );
}

interface ComponentProps {
  fullWidth?: boolean;
}

export default BasePage;