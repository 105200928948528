import React from "react";
import { useLocation } from "react-router-dom";

const QUERY_PARAM_KEYS = {
    SIGNED_OUT_SUCCESS: "signedOutSuccess",
    INVITE_TOKEN: "inviteToken",
    TENANT_ID: "tenantId",
    TENANT_NAME: "tenantName",
    OPEN_UPDATE_OPTIONS: "updateOptions",
    IS_RESET_PASSWORD_LINK: "isResetPasswordLink",
    LOAD_PREVIEW_OPEN: "preview",
    ALL_PAGE_NUMBER: "allPageNum",
    PUBLISHED_PAGE_NUMBER: "pubPageNum",
    SCHEDULED_PAGE_NUMBER: "schPageNum",
    DRAFT_PAGE_NUMBER: "dftPageNum",
    SUBMISSION_PAGE_NUMBER: "sbmPageNum",
    TAB_NUMBER: "tabNum",
    PAGE_CONTEXT: "context"
};

/**
 * Hook for using query parameters
 * - returns a URLSearchParams object
 * - see RequireLogin.tsx for usage example
 */
const useQueryParams = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default useQueryParams;
export { QUERY_PARAM_KEYS };
