import { enUS } from "./translations/en-us.json";

export const en = enUS;
export const fr = Object.keys(enUS).reduce((acc, key) => ({...acc, [key]: `fr - ${enUS[key]}`}) , {})

export const languages = {
  "en-us": en,
  "en-ca": en,
  "en": en,
  "fr-ca": fr,
  "fr": fr
};

export const LANGUAGE_CODES = {
    en: "en"
};

const defaultLangPref = (!!window && !!window.localStorage) ? (window.localStorage.getItem("sparrow-lang-pref") || "en-us") : "en-us";

export const defaultState = {
    locale: defaultLangPref,
    langPref: defaultLangPref,
    messages: languages[defaultLangPref]
} as LocalizationState;

export interface LocalizationState {
  locale: string;
  langPref: string;
  messages?: any;
}

export interface ILanguage {
    availableForTranslation: boolean;
    language: string;
    lcid: string;
    translateAs: string;
    visible: boolean;
}

export type KeyValue = {
    key: string;
    value: string;
}
