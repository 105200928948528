import React, { useEffect, useState } from "react";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import TranslateIcon from "@mui/icons-material/Translate";
import { Button } from "@mui/material";

import CalloutMultiselect from "modules/common/components/forms/inputs/calloutMultiselect";
import { ILanguage, KeyValue } from "../models";

import "../styles/languageBar.sass";

interface ILanguageProps {
    languages: KeyValue[];
    activeLcid: string;
    defaultLcid: string;
    selectedLanguages: ILanguage[];
    anyAvailableTranslationsCheck: boolean;
    hide?: boolean;
    onAddLanguage: (lang: KeyValue) => void;
    onRemoveLanguage: (lang: KeyValue) => void;
    onSelectLanguage: (lang: KeyValue) => void;
    onTranslate: () => void;
    onOpen?: () => void;
}

/**
 * Language selector tool bar
 */
const LanguageBar: React.FunctionComponent<ILanguageProps> = ({
    languages,
    activeLcid,
    selectedLanguages,
    defaultLcid,
    anyAvailableTranslationsCheck,
    hide = false,
    onAddLanguage,
    onRemoveLanguage,
    onTranslate,
    onOpen,
    ...restProps
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [calloutOpen, setCalloutOpen] = useState(false);
    const [translateButtonAvailable, setTranslateButtonAvailable] = useState<boolean>(true);

    useEffect(() => {
        setTranslateButtonAvailable(anyAvailableTranslationsCheck);
    }, [anyAvailableTranslationsCheck])

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onOpen)
            onOpen();

        setAnchorEl(event.currentTarget);
        setCalloutOpen(true);
    }

    /**
     * Close popout and send langauge to parent
     */
    const onSelectLanguage = (lang: KeyValue) => {
        setCalloutOpen(false);
        restProps.onSelectLanguage(lang);
    }

    return (
        <>
            {!hide &&
                <div className="language-bar-container">
                    <Button disableRipple className="language-select-button" onClick={onButtonClick}>
                        <MoreVertIcon />
                        <span>{languages.find((lang: KeyValue) => lang.key === activeLcid)?.value.toLocaleUpperCase()}</span>
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                        startIcon={<TranslateIcon />}
                        onClick={onTranslate}
                        disabled={translateButtonAvailable}
                    >
                        TRANSLATE
                    </Button>
                    {anchorEl && <CalloutMultiselect
                        items={languages}
                        anchorEl={anchorEl}
                        open={calloutOpen}
                        setOpen={setCalloutOpen}
                        defaultValue={defaultLcid}
                        activeValue={activeLcid}
                        selectedValues={selectedLanguages.map((lang: ILanguage) => lang.lcid)}
                        onAddItem={onAddLanguage}
                        onSelectItem={onSelectLanguage}
                        onRemoveItem={onRemoveLanguage}
                    />}
                </div>
            }
        </>
    );
}

export default LanguageBar;
