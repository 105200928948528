import React, {useState} from "react";

import LockIcon from "@mui/icons-material/Lock";
import AddIcon from '@mui/icons-material/Add';

import ChipList from "../../chipList";
import TopicMultiselect from "./topicMultiselect";
import { MultiSelectTopic, MultiSelectTopicGroup } from "modules/settings";

interface ITopicMultiselectAndChips {
    defaultOptions: MultiSelectTopicGroup[];
    selectedOptions?: MultiSelectTopic[];
    containerClassName?: string;
    onChange: (topics: MultiSelectTopic[]) => void;
}

/**
 * Button and list component
 * - button opens multi-select
 * - selections displayed in chips list
 * TODO: MAKE THIS GENERIC (NOT TOPIC SPECIFIC)
 */
const TopicMultiselectAndChips: React.FunctionComponent<ITopicMultiselectAndChips> = ({
    defaultOptions,
    containerClassName,
    selectedOptions = [],
    onChange,
}) => {
    const [filteredOptions, setFilteredOptions] = useState<MultiSelectTopicGroup[]>([...defaultOptions]);
    const [anchorEl, setAnchorEl] = useState(null);

    const onCalloutClose = (anchorEl: any) => {
        setAnchorEl(anchorEl);
    }

    const checkGroupSelected = (tagGroup: MultiSelectTopicGroup) => {
        let selectedIds = selectedOptions.map((selected: MultiSelectTopic) => selected.id);
        return tagGroup.tags.every(tag => {
            return selectedIds.includes(tag.id);
        });
    }

    const handleTagGroupClick = (tagGroup: MultiSelectTopicGroup) => {
        let isCurrentlyChecked = checkGroupSelected(tagGroup);

        if (isCurrentlyChecked) {
            deselectTagGroup(tagGroup);
        } else {
            selectTagGroup(tagGroup);
        }
    }

    const selectTagGroup = (tagGroup: MultiSelectTopicGroup) => {
        let currentTags = [...selectedOptions];

        tagGroup.tags.forEach(tag => {
            if (!currentTags.includes(tag)) {
                currentTags.push(tag);
            }
        });

        onChange(currentTags);
    }

    const deselectTagGroup = (tagGroup: MultiSelectTopicGroup) => {
        let currentTags = [...selectedOptions];
        let tagsToRemove = tagGroup.tags.map((t: MultiSelectTopic) => t.id);

        currentTags = currentTags.filter(currentTag => !tagsToRemove.includes(currentTag.id));

        onChange(currentTags);
    }

    const onHandleAllTags = (tagSelected: boolean) => {
        let newTopics: MultiSelectTopic[] = [];

        if (!tagSelected) {
            newTopics = [...selectedOptions];

            filteredOptions.forEach(tagGroup => {
                tagGroup.tags.forEach(tag => {
                    if (!newTopics.includes(tag)) {
                        newTopics.push(tag);
                    }
                });
            });
        }

        onChange(newTopics);
    }

    const onChangeTags = (currentTag: MultiSelectTopic) => {
        const hasSelectedTag: boolean = !!selectedOptions.find((selectedTag) => selectedTag.id === currentTag.id);
        let newTopics = [...selectedOptions];

        if (hasSelectedTag)
            newTopics = selectedOptions.filter(tag => tag.id !== currentTag.id);
        else
            newTopics.push(currentTag);

        onChange(newTopics);
    }

    return (
        <div className={`${containerClassName || ""}`}>
            <div style={{ width: "100%" }}>
                {defaultOptions.length !== 0 &&
                    <TopicMultiselect
                        defaultOptions={defaultOptions}
                        containerClassName={containerClassName}
                        selectedOptions={selectedOptions}
                        onChange={onChange}
                        startIcon={<AddIcon/>}
                    />
                }
            </div>
            <ChipList
                closable
                onClose={onChangeTags}
                truncateAtIndex={4}
                items={selectedOptions.map((opt: MultiSelectTopic) => ({
                    id: opt.id,
                    name: opt.name,
                    icon: opt.restricted ? <LockIcon style={{ fontSize: "16px", color: "#B72026", position: "relative", top: "2px", marginLeft: "4px" }} /> : undefined
                }))}
            />
        </div>
    );
}

export default TopicMultiselectAndChips;
