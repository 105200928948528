import * as React from "react";
import moment from "moment";

import { CurrentUserResponse, EventTimes as IEventTimes, TranslatedContent } from "../../../models";
import AddToCalendar from "./addToCalendar";

import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import { formatDate, isAllDayEvent } from "utils/dateFormatting";


class EventTimes extends React.Component<ComponentProps, ComponentState> {
  public componentDidMount() {
    moment.locale("en");
  }

  public render() {
    const { showAddToCalendar, eventTimes } = this.props;
    const startTimes: string[] = Object.keys(eventTimes);
    const isMultiDay: boolean = startTimes.length > 1;
    const hasSpecificTimes: boolean = isMultiDay && startTimes.findIndex(startTime => !!eventTimes[startTime]) !== -1;

    return (
      <div className="info-field">
        <div className="info-icon">
          <DateRangeOutlinedIcon fontSize="small" />
        </div>
        <div className={isMultiDay ? "multi-day-event-times" : ""}>
          {startTimes.map((startTime, index) => {
            let isAllDay = isAllDayEvent(eventTimes);
            return (
              <div key={index}>
                <span className="event-times-day">{`${isMultiDay ? `Day ${index + 1} - ` : ""}${isAllDay ? formatDate(new Date(startTime), isAllDay, true) : moment(startTime).format("ddd, MMM D, YYYY")}, `}</span>
                <span className={hasSpecificTimes ? "event-times-time" : ""}>
                  {!eventTimes[startTime]
                    ? `All Day`
                    : `${moment(startTime).format("h:mm A")} - ${moment(eventTimes[startTime]!).format("h:mm A")}`
                  }
                </span>
                {showAddToCalendar &&<AddToCalendar {...this.props} startTime={startTime} />}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}


interface ComponentProps {
  activeLcid: string;
  eventTimes: IEventTimes;
  isCurrentUserAttending?: boolean;
  location?: string;
  locationLink?: string;
  locationLinkLabel?: string;
  respondingEnabled?: boolean;
  translatedContent: TranslatedContent;
  currentUserResponse?: CurrentUserResponse;
  showAddToCalendar?: boolean;
}

interface ComponentState {}

export default EventTimes;