import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import Loading from "modules/common/components/loading";

import { PortalPage } from "../../models";
import PortalPagesEditor from "./portalPagesEditor";


class PortalPagesCreation extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      portalPage: {},
      hasError: false,
      isFetching: true
    };
  }

  componentWillMount() {
    const draftId = this.props.match.params.draftId;
    if (!!draftId) {
      this.props.clearChangedSinceSaved();
      this.getDraft(draftId);
    }
  }

  componentDidUpdate(prevProps: PropsWithRedux) {
    if (!!this.props.match.params.draftId && (this.props.match.params.draftId !== prevProps.match.params.draftId)) {
      this.setState({ portalPage: {}, hasError: false, isFetching: true });
    } else if (this.props.shouldUpdateDraft && !prevProps.shouldUpdateDraft) {
      this.updateDraft(this.props.match.params.draftId);
    }
  }

  public render() {
    return (
      <div>
        <div className="portal-pages-page">
          {this.state.isFetching
            ? <Loading />
            : this.state.hasError
              ? <div className="no-portal-page-data">This page could not be loaded.</div>
              : <PortalPagesEditor portalPage={this.state.portalPage as PortalPage} onChangePortalPage={this.onChangePortalPage} />
          }
        </div>
      </div>
    );
  }

  private getDraft = (draftId: string) => {
    this.props.getDraft(draftId).then((portalPage) => {
      if (!!portalPage) {
        this.setState({ portalPage, hasError: false, isFetching: false });
      } else {
        this.setState({ hasError: true, isFetching: false });
      }
    });
  }

  private updateDraft = (draftId: string) => {
    this.props.getDraft(draftId).then((portalPage) => {
      if (!!portalPage)
        this.setState({ portalPage: { ...this.state.portalPage, audiences: portalPage.audiences, title: portalPage.title, url: portalPage.url }});
    })
  }

  private onChangePortalPage = (value: Partial<PortalPage>) => {
    const portalPage = { ...this.state.portalPage, ...value };
    this.setState({
      ...this.state,
      portalPage: portalPage
    });
    this.props.hasChangedSinceSaved();
  }
}


interface RouteParams {
  tenant: string;
  draftId: string;
}

interface ComponentProps {
}

interface ComponentState {
  portalPage: Partial<PortalPage>;
  hasError: boolean;
  isFetching: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
    ...ownProps,
    shouldUpdateDraft: state.portalPages.shouldUpdateDraft
  }),
  {
    clearChangedSinceSaved: actions.clearChangedSinceSaved,
    getDraft: actions.getDraft,
    hasChangedSinceSaved: actions.hasChangedSinceSaved,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PortalPagesCreation);