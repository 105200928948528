/**
 * Parse html text
 * - find any anchors tags that do not open in new tab and have hash character
 * - prefix href of thos anchor tags with the current url
 */
export const parseAnchors = (body: string) => {
    let updatedHTML = body;
    let html: HTMLDivElement = document.createElement("div");
    html.innerHTML = body;

    let anchors = html.getElementsByTagName("a");

    for (let i = 0; i < anchors.length; i++) {
        const hasAnchor = !!anchors[i].hash && anchors[i].target !== "_blank";
        if (hasAnchor) {
            const anchorIndex = window.location.href.indexOf("#");
            const locationHref = anchorIndex !== -1 ? window.location.href.slice(0, anchorIndex) : window.location.href;
            updatedHTML = updatedHTML.replace(`href="${anchors[i].hash}"`, `href="${locationHref + anchors[i].hash}"`);
        }
    }

    return updatedHTML;
};

declare global {
    interface Navigator {
      msSaveBlob: (blobOrBase64: Blob | string, filename: string) => void
    }
}