import { ApiRequest } from "./network";
import { SparrowApi } from "api/network/adapters/SparrowApi";
import * as actions from "network/actions";

import { DocumentFeedFilters, DocumentFileType, DocumentProperties, DocumentsFilterValues, DocumentsSort } from "modules/documents/models";
import cookie from "utils/cookie";
import { SortStyle } from "utils/managementUtils";


export class DocumentsApi {
  constructor(private _sparrowApi: SparrowApi) {}

  public GetFile = (documentId: string) => {
    return new ApiRequest(
      this._sparrowApi
          .TenantRequest()
          .get(`/{tenant}/ospreyapi/v2/files/${documentId}`, { responseType: "blob" })
    );
  }

  public GetDocumentFile = (documentId: string) => {
    return new ApiRequest(
      this._sparrowApi
          .TenantRequest()
          .get(`/{tenant}/api/v1/documents/${documentId}`, { responseType: "blob" })
    );
  }
}


const convertFileType = (fileType: string): DocumentFileType | "" => {
  if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    return "docx";
  if (fileType === "image/jpeg")
    return "jpeg";
  if (fileType === "application/pdf")
    return "pdf";
  if (fileType === "image/png")
    return "png";
  if (fileType === "application/vnd.openxmlformats-officedocument.presentationml.presentation")
    return "pptx";
  if (fileType === "text/plain")
    return "txt";
  if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    return "xlsx";
  return "";
}

const parseFileName = (fileName: string, fileType: string): string => {
  if (fileName.toLowerCase().endsWith(`.${fileType}`)) {
    const fileTypeIndex = fileName.toLowerCase().lastIndexOf(`.${fileType}`);
    return fileName.slice(0, fileTypeIndex);
  }

  return fileName;
}

export const ReplaceFile = (documentId: string, file: File) => {
  const body = new FormData();
  body.append("document", file);
  return actions.NetworkRequest({
    method: "POST",
    url: `/{tenant}/ospreyapi/v2/files/${documentId}`,
    body: body
  });
}

export const UploadAttachedFile = (file: File) => {
  const fileType: DocumentFileType | "" = convertFileType(file.type);
  const fileName: string = fileType === "jpeg" ? parseFileName(parseFileName(file.name, "jpg"), "jpeg") : parseFileName(file.name, fileType);

  const body = new FormData();
  body.append("document", file);
  return actions.NetworkRequest({
    method: "POST",
    url: `/{tenant}/ospreyapi/v2/files/upload?fileName=${fileName}&fileType=${fileType}&availability=attachmentOnly&autoPublish=true`,
    body: body
  });
}

export const UploadFile = (file: File) => {
  const fileType: DocumentFileType | "" = convertFileType(file.type);
  const fileName: string = fileType === "jpeg" ? parseFileName(parseFileName(file.name, "jpg"), "jpeg") : parseFileName(file.name, fileType);

  const body = new FormData();
  body.append("document", file);
  return actions.NetworkRequest({
    method: "POST",
    url: `/{tenant}/ospreyapi/v2/files/upload?fileName=${fileName}&fileType=${fileType}&availability=onlineOnly&autoPublish=false`,
    body: body
  });
}

export const GetDocumentGallery = (pageNumber: number, text?: string, sort?: SortStyle) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/ospreyapi/v2/files/properties?pageAmount=20&pageNumber=${pageNumber}`,
    body: JSON.stringify({
      states: ["enabled"],
      textToSearch: text ?? "",
      sortType: sort ?? 0
    })
  });
}

export const GetDocuments = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => {
  let rowsPerPage = pageAmount || cookie.getRowsPerPage();
  
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/ospreyapi/v2/files/properties?pageNumber=${pageNumber}&pageAmount=${rowsPerPage}`,
    body: JSON.stringify(filters)
  });
}

export const GetDisabledDocuments = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => {
  let rowsPerPage = pageAmount || cookie.getRowsPerPage();

  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/ospreyapi/v2/files/properties?pageNumber=${pageNumber}&pageAmount=${rowsPerPage}`,
    body: JSON.stringify({
      ...filters,
      states: ["disabled"]
    })
  });
}

export const GetEnabledDocuments = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => {
  let rowsPerPage = pageAmount || cookie.getRowsPerPage();

  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/ospreyapi/v2/files/properties?pageNumber=${pageNumber}&pageAmount=${rowsPerPage}`,
    body: JSON.stringify({
      ...filters,
      states: ["enabled"]
    })
  });
}

export const GetScheduledDocuments = (pageNumber: number, filters: Partial<DocumentsFilterValues>, pageAmount?: number) => {
  let rowsPerPage = pageAmount || cookie.getRowsPerPage();

  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/ospreyapi/v2/files/properties?pageNumber=${pageNumber}&pageAmount=${rowsPerPage}`,
    body: JSON.stringify({
      ...filters,
      states: ["scheduled"]
    })
  });
}

export const GetAttachedArticles = (documentId: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/ospreyapi/v2/files/attached/${documentId}`
  });
}

export const GetDocumentProperties = (documentId: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/ospreyapi/v2/files/properties/${documentId}`
  });
}

export const DeleteDocument = (documentId: string) => {
  return actions.NetworkRequest({
    method: "DELETE",
    url: `/{tenant}/ospreyapi/v2/files/${documentId}`
  });
}

export const DisableDocument = (documentId: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/ospreyapi/v2/files/properties/${documentId}`,
    body: JSON.stringify({ enabled: false })
  });
}

export const EnableDocument = (documentId: string) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/ospreyapi/v2/files/properties/${documentId}`,
    body: JSON.stringify({ enabled: true })
  });
}

export const UpdateDocumentProperties = (documentId: string, properties: Partial<DocumentProperties>) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/ospreyapi/v2/files/properties/${documentId}`,
    body: JSON.stringify(properties)
  });
}


export const GetDocumentFeed = (pageNumber: number, filters: Partial<DocumentFeedFilters>) => {
  return actions.NetworkRequest({
    headers: { "content-type": "application/json" },
    method: "POST",
    url: `/{tenant}/api/v1/documents/feed?pageAmount=12&pageNumber=${pageNumber}`,
    body: JSON.stringify(filters)
  });
}

export const GetDocumentDetails = (documentId: string) => {
  return actions.NetworkRequest({
    method: "GET",
    url: `/{tenant}/api/v1/documents/properties/${documentId}`
  });
}