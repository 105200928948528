import React, { useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import * as actions from "../../actionCreator";

import { NavigationItem } from "../../models";

import TenantLink from "modules/common/components/tenantLink";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Toolbar from "@mui/material/Toolbar";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      borderTopColor: theme.palette.primary.main,
      pointerEvents: "none"
    },
    popoverPaper: {
      borderTop: "4px solid",
      borderTopColor: "inherit",
      marginTop: 5,
      pointerEvents: "auto",
      "& a": {
        color: "inherit",
        flexGrow: 1,
        textDecoration: "none"
      },
      "& $expandIcon": {
        color: theme.palette.primary.main
      }
    },
    popoverSubPaper: {
      borderTop: "4px solid",
      borderTopColor: "inherit",
      pointerEvents: "auto",
      "& a": {
        color: "inherit",
        flexGrow: 1,
        textDecoration: "none"
      }
    },
    expandIcon: {
      cursor: "pointer",
      padding: "5px 5px 20px 5px",
      marginBottom: -15,
      marginRight: -12
    },
    expandNavigation: {
      alignItems: "center",
      cursor: "pointer",
      display: "inline-flex",
      marginBottom: -15,
      paddingBottom: 15
    },
    childItem: {
      boxSizing: "border-box",
      minWidth: 180,
      padding: "10px 15px",
      pointerEvents: "auto",
      "&:hover": {
        backgroundColor: theme.palette.background.default
      }
    }
  })
);

const SubItemListItem: React.FunctionComponent<{ subItem: NavigationItem, isInEditMode?: boolean, theme?: string }> = props => {
  const [showPopover, setShowPopover] = useState(false);
  const [showClickablePopover, setShowClickablePopover] = useState(false);
  const popoverRef = useRef(null);
  
  const classes = useStyles();

  const { subItem } = props;

  const closePopover = () => {
    setShowPopover(false);
    setShowClickablePopover(false);
  };
  const openPopover = () => setShowPopover(true);
  const togglePopover = () => setShowClickablePopover(!showClickablePopover);

  return (
    <ListItem key={subItem.id} ref={popoverRef} onMouseEnter={openPopover} onMouseLeave={closePopover} className={classes.childItem}>
      {props.isInEditMode
        ? <ListItemText primary={subItem.name} />
        : <TenantLink key={subItem.id} to={`~/page/${subItem.url}`}>
            <ListItemText primary={subItem.name} />
          </TenantLink>
      }
      {!!subItem.children && !!subItem.children.length &&
        <React.Fragment>
          <ExpandMoreIcon onClick={togglePopover} className={classes.expandIcon} />
          <Popover
            open={showPopover || showClickablePopover}
            anchorEl={popoverRef.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            PaperProps={{
              onMouseEnter: openPopover,
              onMouseLeave: closePopover
            }}
            onClose={closePopover}
            style={{ borderTopColor: props.theme, marginTop: -5 }}
            classes={{ paper: classes.popoverSubPaper }}
            className={classes.popover}
          >
            <List disablePadding>
              {subItem.children.map((childItem) =>
                <SubItemListItem
                  key={childItem.id}
                  subItem={childItem}
                  isInEditMode={props.isInEditMode}
                  theme={props.theme}
                />
              )}
            </List>
          </Popover>
        </React.Fragment>
      }
    </ListItem>
  );
}

const Item: React.FunctionComponent<{ item: NavigationItem, isInEditMode?: boolean, theme?: string }> = props => {
  const [showPopover, setShowPopover] = useState(false);
  const [showClickablePopover, setShowClickablePopover] = useState(false);
  const popoverAnchor = useRef(null);

  const classes = useStyles();

  const { item, isInEditMode } = props;

  const closePopover = () => {
    setShowPopover(false);
    setShowClickablePopover(false);
  };
  const openPopover = () => setShowPopover(true);
  const togglePopover = () => setShowClickablePopover(!showClickablePopover);

  return (
    <React.Fragment key={item.id}>
      <div onMouseEnter={openPopover} onMouseLeave={closePopover}>
        {item.url &&
          (isInEditMode
            ? <span>{item.name}</span>
            : <TenantLink to={item.isHome ? "~/home" : `~/page/${item.url}`}>{item.name}</TenantLink>
          )
        }
        {!!item.children && !!item.children.length &&
          (!item.url
            ? <div ref={popoverAnchor} onClick={togglePopover} className={classes.expandNavigation}>
                <div>{item.name}</div>
                <ExpandMoreIcon className={classes.expandIcon} />
              </div>
            : <ExpandMoreIcon ref={popoverAnchor} onClick={togglePopover} className={classes.expandIcon} />
          )
        }
        {!!item.children && !!item.children.length &&
          <Popover
            open={showPopover || showClickablePopover}
            anchorEl={popoverAnchor.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            PaperProps={{
              onMouseEnter: openPopover,
              onMouseLeave: closePopover
            }}
            onClose={closePopover}
            style={{ borderTopColor: props.theme }}
            classes={{ paper: classes.popoverPaper }}
            className={classes.popover}
          >
            <List disablePadding>
              {item.children.map((subItem) => (
                <SubItemListItem
                  key={subItem.id}
                  subItem={subItem}
                  isInEditMode={isInEditMode}
                  theme={props.theme}
                />
              ))}
            </List>
          </Popover>
        }
      </div>
    </React.Fragment>
  );
}

const Items: React.FunctionComponent<{ items: NavigationItem[], isInEditMode?: boolean, theme?: string }> = props => {
  const [showPopover, setShowPopover] = useState(false);
  const [showClickablePopover, setShowClickablePopover] = useState(false);
  const popoverRef = useRef(null);

  const classes = useStyles();

  const closePopover = () => {
    setShowPopover(false);
    setShowClickablePopover(false);
  };
  const openPopover = () => setShowPopover(true);
  const togglePopover = () => setShowClickablePopover(!showClickablePopover);

  return (
    <React.Fragment>
      <div>
        <Button ref={popoverRef} onClick={togglePopover} onMouseEnter={openPopover} onMouseLeave={closePopover} className="navigation-icon">
          <MenuIcon />
        </Button>
      </div>
      <Popover
        open={showPopover || showClickablePopover}
        anchorEl={popoverRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        PaperProps={{
          onMouseEnter: openPopover,
          onMouseLeave: closePopover
        }}
        onClose={closePopover}
        style={{ borderTopColor: props.theme, marginTop: 13 }}
        classes={{ paper: classes.popoverPaper }}
        className={classes.popover}
      >
        <List disablePadding>
          {props.items.map((item) =>
            <SubItemListItem
              key={item.id}
              subItem={item}
              isInEditMode={props.isInEditMode}
              theme={props.theme}
            />
          )}
        </List>
      </Popover>
    </React.Fragment>
  );
}

class NavigationItems extends React.Component<PropsWithRedux, {}> {
  public componentDidMount() {
    if (this.props.shouldFetch)
      this.fetchItems();
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.shouldFetch && !prevProps.shouldFetch)
      this.fetchItems();
  }

  public render() {
    const { navigation, isInEditMode } = this.props;

    return (
      <AppBar position="static" elevation={6} style={{ backgroundColor: this.props.theme || "" }}>
        <Toolbar className="navigation">
          <Hidden mdUp>
            <Items
              items={navigation}
              isInEditMode={isInEditMode}
              theme={this.props.theme || ""}
            />
          </Hidden>
          <Hidden mdDown>
            <div className="navigation-links">
              {navigation.slice(0,4).map((item) => (
                <Item key={item.id} item={item} isInEditMode={isInEditMode} theme={this.props.theme || ""} />
              ))}
              {navigation.length > 4 &&
                (navigation.length === 5
                  ? <Item key={navigation[4].id} item={navigation[4]} isInEditMode={isInEditMode} theme={this.props.theme || ""} />
                  : <Item
                      key="more"
                      item={{
                        id: "more",
                        children: navigation.slice(4),
                        isHome: false,
                        name: "More",
                        parentId: "",
                        portalPageId: "",
                        portalPageJson: [],
                        sequence: 5,
                        state: "Enabled",
                        url: ""
                      }}
                      isInEditMode={isInEditMode}
                      theme={this.props.theme || ""}
                    />
                )
              }
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }

  private fetchItems = () => {
    !this.props.isFetching &&
      this.props.getNavigation();
  }
}

interface ComponentProps {
  isInEditMode?: boolean;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    errorMessage: state.portalPages.errorMessage,
    isDeleting: state.portalPages.isDeleting,
    isFetching: state.portalPages.navigation.isFetching,
    isSaving: state.portalPages.isSaving,
    navigation: state.portalPages.navigation.navigationItems,
    shouldFetch: state.portalPages.navigation.shouldFetch,
    theme: state.settings.tenantSettings.themeSettings?.color // overwrite admin theme with client-side theme for preview
  }),
  {
    clearErrorMessage: actions.clearErrorMessage,
    getNavigation: actions.getNavigation
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NavigationItems);