import { Action } from "redux";
import { CompleteAction, ActionCreator } from "typedActions";
import { NavigationItem, PortalPage, PortalPagesListingPage } from "./models";


export const GET_DRAFT = "GET_DRAFT";
export interface GetDraft extends Action {};
export const GetDraft = ActionCreator<GetDraft>(GET_DRAFT);

export const GET_DRAFT_COMPLETE = "GET_DRAFT_COMPLETE";
export interface GetDraftComplete extends CompleteAction {}
export const GetDraftComplete = ActionCreator<GetDraftComplete>(GET_DRAFT_COMPLETE);


export const GET_PORTAL_PAGE = "GET_PORTAL_PAGE";
export interface GetPortalPage extends Action {};
export const GetPortalPage = ActionCreator<GetPortalPage>(GET_PORTAL_PAGE);

export const GET_PORTAL_PAGE_COMPLETE = "GET_PORTAL_PAGE_COMPLETE";
export interface GetPortalPageComplete extends CompleteAction {};
export const GetPortalPageComplete = ActionCreator<GetPortalPageComplete>(GET_PORTAL_PAGE_COMPLETE);

export const GET_PORTAL_PAGES = "GET_PORTAL_PAGES";
export interface GetPortalPages extends Action {};
export const GetPortalPages = ActionCreator<GetPortalPages>(GET_PORTAL_PAGES);

export const GET_PORTAL_PAGES_COMPLETE = "GET_PORTAL_PAGES_COMPLETE";
export interface GetPortalPagesComplete extends CompleteAction { page: PortalPagesListingPage };
export const GetPortalPagesComplete = ActionCreator<GetPortalPagesComplete>(GET_PORTAL_PAGES_COMPLETE);

export const GET_DISABLED_PORTAL_PAGES = "GET_DISABLED_PORTAL_PAGES";
export interface GetDisabledPortalPages extends Action {};
export const GetDisabledPortalPages = ActionCreator<GetDisabledPortalPages>(GET_DISABLED_PORTAL_PAGES);

export const GET_DISABLED_PORTAL_PAGES_COMPLETE = "GET_DISABLED_PORTAL_PAGES_COMPLETE";
export interface GetDisabledPortalPagesComplete extends CompleteAction { page: PortalPagesListingPage };
export const GetDisabledPortalPagesComplete = ActionCreator<GetDisabledPortalPagesComplete>(GET_DISABLED_PORTAL_PAGES_COMPLETE);

export const GET_DRAFT_PORTAL_PAGES = "GET_DRAFT_PORTAL_PAGES";
export interface GetDraftPortalPages extends Action {};
export const GetDraftPortalPages = ActionCreator<GetDraftPortalPages>(GET_DRAFT_PORTAL_PAGES);

export const GET_DRAFT_PORTAL_PAGES_COMPLETE = "GET_DRAFT_PORTAL_PAGES_COMPLETE";
export interface GetDraftPortalPagesComplete extends CompleteAction { page: PortalPagesListingPage };
export const GetDraftPortalPagesComplete = ActionCreator<GetDraftPortalPagesComplete>(GET_DRAFT_PORTAL_PAGES_COMPLETE);

export const GET_ENABLED_PORTAL_PAGES = "GET_ENABLED_PORTAL_PAGES";
export interface GetEnabledPortalPages extends Action {};
export const GetEnabledPortalPages = ActionCreator<GetEnabledPortalPages>(GET_ENABLED_PORTAL_PAGES);

export const GET_ENABLED_PORTAL_PAGES_COMPLETE = "GET_ENABLED_PORTAL_PAGES_COMPLETE";
export interface GetEnabledPortalPagesComplete extends CompleteAction { page: PortalPagesListingPage };
export const GetEnabledPortalPagesComplete = ActionCreator<GetEnabledPortalPagesComplete>(GET_ENABLED_PORTAL_PAGES_COMPLETE);

export const GET_NAVIGATION = "GET_NAVIGATION";
export interface GetNavigation extends Action {};
export const GetNavigation = ActionCreator<GetNavigation>(GET_NAVIGATION);

export const GET_NAVIGATION_COMPLETE = "GET_NAVIGATION_COMPLETE";
export interface GetNavigationComplete extends CompleteAction { navigationItems: NavigationItem[] };
export const GetNavigationComplete = ActionCreator<GetNavigationComplete>(GET_NAVIGATION_COMPLETE);

export const GET_NAVIGATION_ITEM_BY_URL = "GET_NAVIGATION_ITEM_BY_URL";
export interface GetNavigationItemByURL extends Action {};
export const GetNavigationItemByURL = ActionCreator<GetNavigationItemByURL>(GET_NAVIGATION_ITEM_BY_URL);

export const GET_NAVIGATION_ITEM_BY_URL_COMPLETE = "GET_NAVIGATION_ITEM_BY_URL_COMPLETE";
export interface GetNavigationItemByURLComplete extends CompleteAction {};
export const GetNavigationItemByURLComplete = ActionCreator<GetNavigationItemByURLComplete>(GET_NAVIGATION_ITEM_BY_URL_COMPLETE);

export const GET_NAVIGATION_ITEMS = "GET_NAVIGATION_ITEMS";
export interface GetNavigationItems extends Action {};
export const GetNavigationItems = ActionCreator<GetNavigationItems>(GET_NAVIGATION_ITEMS);

export const GET_NAVIGATION_ITEMS_COMPLETE = "GET_NAVIGATION_ITEMS_COMPLETE";
export interface GetNavigationItemsComplete extends CompleteAction { navigationItems: NavigationItem[] };
export const GetNavigationItemsComplete = ActionCreator<GetNavigationItemsComplete>(GET_NAVIGATION_ITEMS_COMPLETE);

export const GET_NAVIGATION_PORTAL_PAGES = "GET_NAVIGATION_PORTAL_PAGES";
export interface GetNavigationPortalPages extends Action {};
export const GetNavigationPortalPages = ActionCreator<GetNavigationPortalPages>(GET_NAVIGATION_PORTAL_PAGES);

export const GET_NAVIGATION_PORTAL_PAGES_COMPLETE = "GET_NAVIGATION_PORTAL_PAGES_COMPLETE";
export interface GetNavigationPortalPagesComplete extends CompleteAction {};
export const GetNavigationPortalPagesComplete = ActionCreator<GetNavigationPortalPagesComplete>(GET_NAVIGATION_PORTAL_PAGES_COMPLETE);


export const HIDE_BACK_TO_PORTAL_PAGES = "HIDE_BACK_TO_PORTAL_PAGES";
export interface HideBackToPortalPages extends Action {};
export const HideBackToPortalPages = ActionCreator<HideBackToPortalPages>(HIDE_BACK_TO_PORTAL_PAGES);

export const HIDE_GLOBAL_COMPONENTS = "HIDE_GLOBAL_COMPONENTS";
export interface HideGlobalComponents extends Action {};
export const HideGlobalComponents = ActionCreator<HideGlobalComponents>(HIDE_GLOBAL_COMPONENTS);

export const HIDE_GO_TO_NAVIGATION_ITEMS = "HIDE_GO_TO_NAVIGATION_ITEMS";
export interface HideGoToNavigationItems extends Action {};
export const HideGoToNavigationItems = ActionCreator<HideGoToNavigationItems>(HIDE_GO_TO_NAVIGATION_ITEMS);

export const HIDE_NEW_PORTAL_PAGE = "HIDE_NEW_PORTAL_PAGE";
export interface HideNewPortalPage extends Action {};
export const HideNewPortalPage = ActionCreator<HideNewPortalPage>(HIDE_NEW_PORTAL_PAGE);

export const HIDE_PREVIEW = "HIDE_PREVIEW";
export interface HidePreview extends Action {};
export const HidePreview = ActionCreator<HidePreview>(HIDE_PREVIEW);

export const HIDE_PUBLISH_CHANGES = "HIDE_PUBLISH_CHANGES";
export interface HidePublishChanges extends Action {};
export const HidePublishChanges = ActionCreator<HidePublishChanges>(HIDE_PUBLISH_CHANGES);

export const SHOW_BACK_TO_PORTAL_PAGES = "SHOW_BACK_TO_PORTAL_PAGES";
export interface ShowBackToPortalPages extends Action {};
export const ShowBackToPortalPages = ActionCreator<ShowBackToPortalPages>(SHOW_BACK_TO_PORTAL_PAGES);

export const SHOW_GLOBAL_COMPONENTS = "SHOW_GLOBAL_COMPONENTS";
export interface ShowGlobalComponents extends Action {};
export const ShowGlobalComponents = ActionCreator<ShowGlobalComponents>(SHOW_GLOBAL_COMPONENTS);

export const SHOW_GO_TO_NAVIGATION_ITEMS = "SHOW_GO_TO_NAVIGATION_ITEMS";
export interface ShowGoToNavigationItems extends Action {};
export const ShowGoToNavigationItems = ActionCreator<ShowGoToNavigationItems>(SHOW_GO_TO_NAVIGATION_ITEMS);

export const SHOW_NEW_PORTAL_PAGE = "SHOW_NEW_PORTAL_PAGE";
export interface ShowNewPortalPage extends Action {};
export const ShowNewPortalPage = ActionCreator<ShowNewPortalPage>(SHOW_NEW_PORTAL_PAGE);

export const SHOW_PREVIEW = "SHOW_PREVIEW";
export interface ShowPreview extends Action { page: PortalPage };
export const ShowPreview = ActionCreator<ShowPreview>(SHOW_PREVIEW);

export const SHOW_PUBLISH_CHANGES = "SHOW_PUBLISH_CHANGES";
export interface ShowPublishChanges extends Action {};
export const ShowPublishChanges = ActionCreator<ShowPublishChanges>(SHOW_PUBLISH_CHANGES);



export const CHECK_IF_URL_EXISTS = "CHECK_IF_URL_EXISTS";
export interface CheckIfUrlExists extends Action {};
export const CheckIfUrlExists = ActionCreator<CheckIfUrlExists>(CHECK_IF_URL_EXISTS);

export const CREATE_NEW_CHILD_NAVIGATION_ITEM = "CREATE_NEW_CHILD_NAVIGATION_ITEM";
export interface CreateNewChildNavigationItem extends Action {};
export const CreateNewChildNavigationItem = ActionCreator<CreateNewChildNavigationItem>(CREATE_NEW_CHILD_NAVIGATION_ITEM);

export const CREATE_NEW_CHILD_NAVIGATION_ITEM_COMPLETE = "CREATE_NEW_CHILD_NAVIGATION_ITEM_COMPLETE";
export interface CreateNewChildNavigationItemComplete extends CompleteAction {};
export const CreateNewChildNavigationItemComplete = ActionCreator<CreateNewChildNavigationItemComplete>(CREATE_NEW_CHILD_NAVIGATION_ITEM_COMPLETE);

export const CREATE_NEW_DRAFT = "CREATE_NEW_DRAFT";
export interface CreateNewDraft extends Action {};
export const CreateNewDraft = ActionCreator<CreateNewDraft>(CREATE_NEW_DRAFT);

export const CREATE_NEW_DRAFT_COMPLETE = "CREATE_NEW_DRAFT_COMPLETE";
export interface CreateNewDraftComplete extends CompleteAction {};
export const CreateNewDraftComplete = ActionCreator<CreateNewDraftComplete>(CREATE_NEW_DRAFT_COMPLETE);

export const CREATE_NEW_NAVIGATION_ITEM = "CREATE_NEW_NAVIGATION_ITEM";
export interface CreateNewNavigationItem extends Action {};
export const CreateNewNavigationItem = ActionCreator<CreateNewNavigationItem>(CREATE_NEW_NAVIGATION_ITEM);

export const CREATE_NEW_NAVIGATION_ITEM_COMPLETE = "CREATE_NEW_NAVIGATION_ITEM_COMPLETE";
export interface CreateNewNavigationItemComplete extends CompleteAction {};
export const CreateNewNavigationItemComplete = ActionCreator<CreateNewNavigationItemComplete>(CREATE_NEW_NAVIGATION_ITEM_COMPLETE);

export const DELETE_DRAFT = "DELETE_DRAFT";
export interface DeleteDraft extends Action {};
export const DeleteDraft = ActionCreator<DeleteDraft>(DELETE_DRAFT);

export const DELETE_DRAFT_COMPLETE = "DELETE_DRAFT_COMPLETE";
export interface DeleteDraftComplete extends CompleteAction {};
export const DeleteDraftComplete = ActionCreator<DeleteDraftComplete>(DELETE_DRAFT_COMPLETE);

export const DELETE_NAVIGATION_ITEM = "DELETE_NAVIGATION_ITEM";
export interface DeleteNavigationItem extends Action {};
export const DeleteNavigationItem = ActionCreator<DeleteNavigationItem>(DELETE_NAVIGATION_ITEM);

export const DELETE_NAVIGATION_ITEM_COMPLETE = "DELETE_NAVIGATION_ITEM_COMPLETE";
export interface DeleteNavigationItemComplete extends CompleteAction {};
export const DeleteNavigationItemComplete = ActionCreator<DeleteNavigationItemComplete>(DELETE_NAVIGATION_ITEM_COMPLETE);

export const MODIFY_DRAFT_DETAILS = "MODIFY_DRAFT_DETAILS";
export interface ModifyDraftDetails extends Action {};
export const ModifyDraftDetails = ActionCreator<ModifyDraftDetails>(MODIFY_DRAFT_DETAILS);

export const MODIFY_DRAFT_DETAILS_COMPLETE = "MODIFY_DRAFT_DETAILS_COMPLETE";
export interface ModifyDraftDetailsComplete extends CompleteAction {};
export const ModifyDraftDetailsComplete = ActionCreator<ModifyDraftDetailsComplete>(MODIFY_DRAFT_DETAILS_COMPLETE);

export const MODIFY_DRAFT_TITLE = "MODIFY_DRAFT_TITLE";
export interface ModifyDraftTitle extends Action {};
export const ModifyDraftTitle = ActionCreator<ModifyDraftTitle>(MODIFY_DRAFT_TITLE);

export const MODIFY_DRAFT_TITLE_COMPLETE = "MODIFY_DRAFT_TITLE_COMPLETE";
export interface ModifyDraftTitleComplete extends CompleteAction {};
export const ModifyDraftTitleComplete = ActionCreator<ModifyDraftTitleComplete>(MODIFY_DRAFT_TITLE_COMPLETE);

export const MODIFY_PORTAL_PAGE_DETAILS = "MODIFY_PORTAL_PAGE_DETAILS";
export interface ModifyPortalPageDetails extends Action {};
export const ModifyPortalPageDetails = ActionCreator<ModifyPortalPageDetails>(MODIFY_PORTAL_PAGE_DETAILS);

export const MODIFY_PORTAL_PAGE_DETAILS_COMPLETE = "MODIFY_PORTAL_PAGE_DETAILS_COMPLETE";
export interface ModifyPortalPageDetailsComplete extends CompleteAction {};
export const ModifyPortalPageDetailsComplete = ActionCreator<ModifyPortalPageDetailsComplete>(MODIFY_PORTAL_PAGE_DETAILS_COMPLETE);

export const MODIFY_PORTAL_PAGE_TITLE = "MODIFY_PORTAL_PAGE_TITLE";
export interface ModifyPortalPageTitle extends Action {};
export const ModifyPortalPageTitle = ActionCreator<ModifyPortalPageTitle>(MODIFY_PORTAL_PAGE_TITLE);

export const MODIFY_PORTAL_PAGE_TITLE_COMPLETE = "MODIFY_PORTAL_PAGE_TITLE_COMPLETE";
export interface ModifyPortalPageTitleComplete extends CompleteAction {};
export const ModifyPortalPageTitleComplete = ActionCreator<ModifyPortalPageTitleComplete>(MODIFY_PORTAL_PAGE_TITLE_COMPLETE);

export const PUBLISH_DRAFT = "PUBLISH_DRAFT";
export interface PublishDraft extends Action {};
export const PublishDraft = ActionCreator<PublishDraft>(PUBLISH_DRAFT);

export const PUBLISH_DRAFT_COMPLETE = "PUBLISH_DRAFT_COMPLETE";
export interface PublishDraftComplete extends CompleteAction {};
export const PublishDraftComplete = ActionCreator<PublishDraftComplete>(PUBLISH_DRAFT_COMPLETE);

export const SAVE_DRAFT = "SAVE_DRAFT";
export interface SaveDraft extends Action {};
export const SaveDraft = ActionCreator<SaveDraft>(SAVE_DRAFT);

export const SAVE_DRAFT_COMPLETE = "SAVE_DRAFT_COMPLETE";
export interface SaveDraftComplete extends CompleteAction {};
export const SaveDraftComplete = ActionCreator<SaveDraftComplete>(SAVE_DRAFT_COMPLETE);

export const SET_NAVIGATION_ITEM_AS_PARENT = "SET_NAVIGATION_ITEM_AS_PARENT";
export interface SetNavigationItemAsParent extends Action {};
export const SetNavigationItemAsParent = ActionCreator<SetNavigationItemAsParent>(SET_NAVIGATION_ITEM_AS_PARENT);

export const SET_NAVIGATION_ITEM_AS_PARENT_COMPLETE = "SET_NAVIGATION_ITEM_AS_PARENT_COMPLETE";
export interface SetNavigationItemAsParentComplete extends CompleteAction {};
export const SetNavigationItemAsParentComplete = ActionCreator<SetNavigationItemAsParentComplete>(SET_NAVIGATION_ITEM_AS_PARENT_COMPLETE);

export const SET_PORTAL_PAGE_AS_HOME = "SET_PORTAL_PAGE_AS_HOME";
export interface SetPortalPageAsHome extends Action {};
export const SetPortalPageAsHome = ActionCreator<SetPortalPageAsHome>(SET_PORTAL_PAGE_AS_HOME);

export const SET_PORTAL_PAGE_AS_HOME_COMPLETE = "SET_PORTAL_PAGE_AS_HOME_COMPLETE";
export interface SetPortalPageAsHomeComplete extends CompleteAction {};
export const SetPortalPageAsHomeComplete = ActionCreator<SetPortalPageAsHomeComplete>(SET_PORTAL_PAGE_AS_HOME_COMPLETE);

export const UPDATE_DRAFT = "UPDATE_DRAFT";
export interface UpdateDraft extends Action {};
export const UpdateDraft = ActionCreator<UpdateDraft>(UPDATE_DRAFT);

export const UPDATE_NAVIGATION_ITEM_SEQUENCE = "UPDATE_NAVIGATION_ITEM_SEQUENCE";
export interface UpdateNavigationItemSequence extends Action {};
export const UpdateNavigationItemSequence = ActionCreator<UpdateNavigationItemSequence>(UPDATE_NAVIGATION_ITEM_SEQUENCE);

export const UPDATE_NAVIGATION_ITEM_SEQUENCE_COMPLETE = "UPDATE_NAVIGATION_ITEM_SEQUENCE_COMPLETE";
export interface UpdateNavigationItemSequenceComplete extends CompleteAction {};
export const UpdateNavigationItemSequenceComplete = ActionCreator<UpdateNavigationItemSequenceComplete>(UPDATE_NAVIGATION_ITEM_SEQUENCE_COMPLETE);


export const CLONE_PORTAL_PAGE = "CLONE_PORTAL_PAGE";
export interface ClonePortalPage extends Action {};
export const ClonePortalPage = ActionCreator<ClonePortalPage>(CLONE_PORTAL_PAGE);

export const CLONE_PORTAL_PAGE_COMPLETE = "CLONE_PORTAL_PAGE_COMPLETE";
export interface ClonePortalPageComplete extends CompleteAction {};
export const ClonePortalPageComplete = ActionCreator<ClonePortalPageComplete>(CLONE_PORTAL_PAGE_COMPLETE);

export const DELETE_PORTAL_PAGE = "DELETE_PORTAL_PAGE";
export interface DeletePortalPage extends Action {};
export const DeletePortalPage = ActionCreator<DeletePortalPage>(DELETE_PORTAL_PAGE);

export const DELETE_PORTAL_PAGE_COMPLETE = "DELETE_PORTAL_PAGE_COMPLETE";
export interface DeletePortalPageComplete extends CompleteAction {};
export const DeletePortalPageComplete = ActionCreator<DeletePortalPageComplete>(DELETE_PORTAL_PAGE_COMPLETE);

export const DELETE_PORTAL_PAGES = "DELETE_PORTAL_PAGES";
export interface DeletePortalPages extends Action {};
export const DeletePortalPages = ActionCreator<DeletePortalPages>(DELETE_PORTAL_PAGES);

export const DELETE_PORTAL_PAGES_COMPLETE = "DELETE_PORTAL_PAGES_COMPLETE";
export interface DeletePortalPagesComplete extends CompleteAction {};
export const DeletePortalPagesComplete = ActionCreator<DeletePortalPagesComplete> (DELETE_PORTAL_PAGES_COMPLETE);

export const DISABLE_PORTAL_PAGE = "DISABLE_PORTAL_PAGE";
export interface DisablePortalPage extends Action {};
export const DisablePortalPage = ActionCreator<DisablePortalPage>(DISABLE_PORTAL_PAGE);

export const DISABLE_PORTAL_PAGE_COMPLETE = "DISABLE_PORTAL_PAGE_COMPLETE";
export interface DisablePortalPageComplete extends CompleteAction {};
export const DisablePortalPageComplete = ActionCreator<DisablePortalPageComplete>(DISABLE_PORTAL_PAGE_COMPLETE);

export const ENABLE_PORTAL_PAGE = "ENABLE_PORTAL_PAGE";
export interface EnablePortalPage extends Action {};
export const EnablePortalPage = ActionCreator<EnablePortalPage>(ENABLE_PORTAL_PAGE);

export const ENABLE_PORTAL_PAGE_COMPLETE = "ENABLE_PORTAL_PAGE_COMPLETE";
export interface EnablePortalPageComplete extends CompleteAction {};
export const EnablePortalPageComplete = ActionCreator<EnablePortalPageComplete>(ENABLE_PORTAL_PAGE_COMPLETE);


export const CHANGED_SINCE_SAVED = "CHANGED_SINCE_SAVED";
export interface ChangedSinceSaved extends Action {};
export const ChangedSinceSaved = ActionCreator<ChangedSinceSaved>(CHANGED_SINCE_SAVED);

export const CLEAR_CHANGED_SINCE_SAVED = "CLEAR_CHANGED_SINCE_SAVED";
export interface ClearChangedSinceSaved extends Action {}
export const ClearChangedSinceSaved = ActionCreator<ClearChangedSinceSaved>(CLEAR_CHANGED_SINCE_SAVED);

export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export interface ClearErrorMessage extends Action {};
export const ClearErrorMessage = ActionCreator<ClearErrorMessage>(CLEAR_ERROR_MESSAGE);

export const CLEAR_PORTAL_PAGES = "CLEAR_PORTAL_PAGES";
export interface ClearPortalPages extends Action {};
export const ClearPortalPages = ActionCreator<ClearPortalPages>(CLEAR_PORTAL_PAGES);

export const CLEAR_PORTAL_PAGES_LIST = "CLEAR_PORTAL_PAGES_LIST";
export interface ClearPortalPagesList extends Action {};
export const ClearPortalPagesList = ActionCreator<ClearPortalPagesList>(CLEAR_PORTAL_PAGES_LIST);

export const CLEAR_SHOULD_FETCH = "CLEAR_SHOULD_FETCH";
export interface ClearShouldFetch extends Action {};
export const ClearShouldFetch = ActionCreator<ClearShouldFetch>(CLEAR_SHOULD_FETCH);

export const CLEAR_SHOW_PUBLISH_SUCCESS = "CLEAR_SHOW_PUBLISH_SUCCESS";
export interface ClearShowPublishSuccess extends Action {};
export const ClearShowPublishSuccess = ActionCreator<ClearShowPublishSuccess>(CLEAR_SHOW_PUBLISH_SUCCESS);

export const CLEAR_SUCCESS_MESSAGE = "CLEAR_SUCCESS_MESSAGE";
export interface ClearSuccessMessage extends Action {};
export const ClearSuccessMessage = ActionCreator<ClearSuccessMessage>(CLEAR_SUCCESS_MESSAGE);