import React from "react";

import Snackbar from "@mui/material/Snackbar";

import Alert from '@mui/material/Alert';

import { Theme } from "@mui/material/styles";


import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      position: "absolute"
    },
    successAlert: {
        backgroundColor: "#366531",
        minWidth: 350
    }
  })
);


/**
 * A success snackbar
 * - if you need more control over the base MUI components use snackbarWrapper.tsx
 */
const SuccessSnackbar: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles();

  return (
    <Snackbar open={!!props.successMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={null} onClose={props.clearSuccessMessage} className={classes.snackbar}>
      <Alert variant="filled" className={classes.successAlert} severity="success" elevation={6} onClose={props.clearSuccessMessage}>{props.successMessage}</Alert>
    </Snackbar>
  );
}

interface ComponentProps {
  successMessage: string;
  clearSuccessMessage: () => void;
}

export default SuccessSnackbar;
