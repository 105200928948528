import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import InfoHover from "modules/common/components/hovers/infoHover";
import Loading from "modules/common/components/loading";

import { EventFeedItem } from "modules/events/models";
import { PostFeedItem } from "modules/posts/models";

import { FeaturedContent, NewsletterIssue } from "../../models";

import SearchEvents from "./searchEvents";
import SearchPosts from "./searchPosts";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";

import moment from "moment";
import { UserRoles } from "modules/authorization/models";


interface Pin extends FeaturedContent {
  author: string;
  bannerColor: string;
  imageUrl: string;
  time: string;
}

class PinnedContent extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      currentSelection: { ...this.defaultContent() },
      isAdding: false,
      isLoading: !!props.issue.featuredContent && !!props.issue.featuredContent.length,
      isSearching: false,
      pins: [],
      searchText: ""
    };
  }

  public componentDidMount() {
    moment.locale("en");

    this.getPins();
  }

  public render() {
    return (
      <div className="newsletter-issue-section">
        <div className="newsletter-issue-section-header">
          <span>Pinned Content in Featured Section</span>
          <InfoHover>The Featured Section of your quick newsletter hosts the events and/or posts you want to pin despite the employee's read status.</InfoHover>
        </div>
        {this.state.isLoading
          ? <Loading padding={12} />
          : <React.Fragment>
              {this.state.pins.map((pin, index) =>
                this.getContentSelection(pin, index + 1)
              )}
              <Collapse in={this.state.isAdding}>
                {this.getContentSelection(this.state.currentSelection, this.state.pins.length + 1)}
              </Collapse>
              <Button variant="text" color="primary" startIcon={<AddIcon />} disabled={this.state.isAdding} onClick={this.onAddContent} className="add-featured-content">Pinned content</Button>
            </React.Fragment>
        }
      </div>
    );
  }

  private defaultContent = (): Pin => {
    return {
      contentType: "Post",
      author: "",
      bannerColor: "",
      id: "",
      imageUrl: "",
      name: "",
      time: ""
    };
  }

  private getContentSelection = (pin: Pin, index: number) => {
    return (
      <div key={`pin-id-${index}`} className="featured-content">
        <div>Pinned Post or Event {index}</div>
        {!!pin.id
          ? <div className="pin">
              <div className="pinned-image">
                {!!pin.imageUrl
                  ? <img src={pin.imageUrl} alt="" />
                  : <div style={{ backgroundColor: pin.bannerColor }}></div>
                }
              </div>
              <div className="pinned-content">
                <div className="title">{pin.name}</div>
                <div className="author">{pin.author}</div>
                <div className="published">{pin.contentType === "Event" ? "Event start date:" : "Published"} {moment(pin.time).format("MMM D, YYYY")}</div>
              </div>
              <IconButton size="small" onClick={() => this.onRemovePin(pin)} className="remove-pin">
                <ClearIcon />
              </IconButton>
            </div>
          : <React.Fragment>
              <div className="featured-content-options">
                <FormControl component="fieldset">
                  <RadioGroup value={pin.contentType} onChange={this.onChangeContentType}>
                    <div className="featured-content-option">
                      <FormControlLabel value="Post" control={<Radio color="primary" />} label="Pin a post" />
                      {pin.contentType === "Post" &&
                        <SearchPosts 
                            onPin={this.onPinPost} 
                            onSearch={(text) => this.props.searchPosts(text, 1, this.props.usersRoles.includes(UserRoles.Owner))} 
                        />
                      }
                    </div>
                    <div className="featured-content-option">
                      <FormControlLabel value="Event" control={<Radio color="primary" />} label="Pin an event" />
                      {pin.contentType === "Event" &&
                        <SearchEvents onPin={this.onPinEvent} onSearch={(text) => this.props.searchEvents(text, 1)} />
                      }
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
              <IconButton onClick={this.onRemoveCurrentSelection} size="large">
                <DeleteIcon />
              </IconButton>
            </React.Fragment>
        }
      </div>
    );
  }

  private getPins = async () => {
    const pins: Pin[] = await Promise.all((this.props.issue.featuredContent || []).map((feature) => this.convertToPin(feature)));
    this.setState({ isLoading: false, pins });
  }


  private convertToPin = async (featuredContent: FeaturedContent): Promise<Pin> => {
    if (featuredContent.contentType === "Event")
      return this.props.getEvent(featuredContent.id).then((event) => {
        return {
          ...featuredContent,
          author: event.author.name,
          bannerColor: event.bannerColor,
          imageUrl: event.imageUrl,
          time: event.eventStartTime
        };
      });
    else
      return this.props.getPost(featuredContent.id).then((post) => {
        return {
          ...featuredContent,
          author: post.author.name,
          bannerColor: post.bannerColor,
          imageUrl: post.imageUrl,
          time: post.publishTime
        };
      });
  }


  private onAddContent = () => {
    this.setState({ isAdding: true, currentSelection: { ...this.defaultContent() } });
  }

  private onChangeContentType = (event) => {
    this.setState({ currentSelection: { ...this.state.currentSelection, contentType: event.target.value }, searchText: "" });
  }

  private onPinEvent = (event: EventFeedItem) => {
    const featuredContent: FeaturedContent = { contentType: "Event", id: event.id, name: event.title };
    this.props.onChange({ featuredContent: (this.props.issue.featuredContent || []).concat([featuredContent]) });
    this.setState({ isAdding: false, pins: this.state.pins.concat([{ ...featuredContent, author: event.author.name, bannerColor: event.bannerColor, imageUrl: event.imageUrl, time: event.eventStartTime }]) });
  }

  private onPinPost = (post: PostFeedItem) => {
    const featuredContent: FeaturedContent = { contentType: "Post", id: post.id, name: post.title };
    this.props.onChange({ featuredContent: (this.props.issue.featuredContent || []).concat([featuredContent]) });
    this.setState({ isAdding: false, pins: this.state.pins.concat([{ ...featuredContent, author: post.author.name, bannerColor: post.bannerColor, imageUrl: post.imageUrl, time: post.publishTime }]) });
  }

  private onRemoveCurrentSelection = () => {
    this.setState({ isAdding: false });
  }

  private onRemovePin = (pin: Pin) => {
    this.props.onChange({ featuredContent: this.props.issue.featuredContent!.filter((currentFeature) => currentFeature.id !== pin.id ) });
    this.setState({ pins: this.state.pins.filter((currentPin) => currentPin.id !== pin.id )});
  }
}


interface ComponentProps {
  issue: Partial<NewsletterIssue>;
  onChange: (issue: Partial<NewsletterIssue>) => void;
}

interface ComponentState {
  currentSelection: Pin;
  isAdding: boolean;
  isLoading: boolean;
  isSearching: boolean;
  pins: Pin[];
  searchText: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        usersRoles: state.settings.currentUser.roles
    }),
    {
        getEvent: actions.getEvent,
        getPost: actions.getPost,
        searchEvents: actions.searchEvents,
        searchPosts: actions.searchPosts,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PinnedContent);