import { Action } from 'redux';
import * as actions from './actions';
import { IAdminLayoutState } from './models';

const DEFAULT_STATE: IAdminLayoutState = {
    mainStyle: undefined,
};

export const actionHandler:  { [actionType: string]: (state: IAdminLayoutState, action: Action) => IAdminLayoutState } = {
    [actions.SET_MAIN_STYLE]: (state: IAdminLayoutState, action: actions.ISetMainStyle) => {
        return {
            ...state,
            mainStyle: action.newStyle
        }
    },
    [actions.SET_HEADER_STYLE]: (state: IAdminLayoutState, action: actions.ISetHeaderStyle) => {
        return {
            ...state,
            headerStyle: action.newStyle
        }
    }
}

export const reducer = (state: IAdminLayoutState, action: Action) => {
    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
      return actionHandlerMethod(state, action);
    }

    return state || DEFAULT_STATE;
};
