import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";

import Loading from "modules/common/components/loading";
import PageNotFound from "pages/pageNotFound/pageNotFound";
import Page from "../../tinacms/page";

import { NavigationItem } from "../../models";


class PortalPageView extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      isLoading: false,
      navigationItem: undefined
    };
  }

  public componentDidMount() {
    this.getNavigationItemByURL();
  }

  public componentDidUpdate(prevProps: PropsWithRedux) {
    if (this.props.portalPageUrl !== prevProps.portalPageUrl)
      this.getNavigationItemByURL();
  }

  public render() {
    const { isLoading, navigationItem } = this.state;

    if (isLoading)
      return <Loading />;

    if (!navigationItem)
      return <PageNotFound />;

    return (
      <Page data={navigationItem.portalPageJson[0]} />
    );
  }

  private getNavigationItemByURL = () => {
    this.setState({ isLoading: true });
    const url: string = this.props.portalPageUrl || "home";
    this.props.getNavigationItemByURL(url).then(navigationItem => {
      this.setState({ isLoading: false, navigationItem });
    });
  }
}

interface ComponentProps {
  portalPageUrl: string;
}

interface ComponentState {
  isLoading: boolean;
  navigationItem: NavigationItem | undefined;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenant: state.tenant.id
  }),
  {
    getNavigationItemByURL: actions.getNavigationItemByURL,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PortalPageView);