import React from "react";

import { AppBar, Button, Dialog, IconButton, Slide, SlideProps, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';

import DesktopPreview from "../desktopPreview";

import "../styles/preview.sass";
import LoadingOverlay from "../../loadingOverlay";

interface IPreviewProps {
    open: boolean;
    desktopPreview?: JSX.Element;
    editLabel?: string;
    openLabel?: string;
    publishLabel?: string;
    extraLabel?: string;
    extraLabelStartIcon?: JSX.Element;
    onClose: () => void;
    openAction?: () => void;
    editAction?: () => void;
    publishAction?: () => void;
    extraAction?: () => void;
    isLoading?: boolean;
}

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Preview: React.FunctionComponent<IPreviewProps> = ({isLoading = false, ...props}) => {
    return <>
        <Dialog
            TransitionComponent={Transition}
            fullScreen
            maxWidth={false}
            open={props.open}
            onClose={props.onClose}
            disableEscapeKeyDown={isLoading}
        >
            <LoadingOverlay show={isLoading}/>
            <AppBar className="preview-appbar" elevation={0} style={{
                backgroundColor: "#ffffff",
            }}>
                <Toolbar className="preview-toolbar">
                    <p className="preview-title">Preview</p>
                    <div className="preview-actions">
                        {
                            props.openAction && props.openLabel &&
                            <Button disabled={isLoading} className="action" endIcon={<LaunchIcon />} variant="text" color="primary" onClick={props.openAction}>
                                {props.openLabel}
                            </Button>
                        }
                        {
                            props.editAction && props.editLabel &&
                            <Button disabled={isLoading} className="action" color="primary" variant="outlined" onClick={props.editAction}>
                                {props.editLabel}
                            </Button>
                        }
                        {
                            props.publishAction && props.publishLabel &&
                            <Button disabled={isLoading} className="action" color="primary" variant="contained" onClick={props.publishAction}>
                                {props.publishLabel}
                            </Button>
                        }
                        {
                            props.extraAction && props.extraLabel &&
                            <Button
                                disabled={isLoading} 
                                className="action" 
                                startIcon={props.extraLabelStartIcon} 
                                variant="outlined" 
                                color="primary" 
                                onClick={props.extraAction}
                            >
                                {props.extraLabel}
                            </Button>
                        }
                        <IconButton
                            disabled={isLoading} 
                            edge="end"
                            color="inherit"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => props.onClose()}
                            aria-label="close"
                            size="large"
                        >
                            <CloseIcon htmlColor="#7f7f7f" />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="preview-content">
            {
                props.desktopPreview && 
                <>
                    <DesktopPreview>
                        {props.desktopPreview}
                    </DesktopPreview>
                    <p className="preview-help">Preview varies based on device and/or platform.</p>
                </>
            }
            {props.children}
            </div>
        </Dialog>
    </>;
}

export default Preview;
