import React, { useEffect, useState } from "react";
import confirm from "utils/notyPopups";

import { DEFAULT_TRANSLATABLE_CONTENT, TranslatableContent, TranslatedContent } from "modules/common/components/authoring/models";
import LanguageBar from "modules/localization/components/languageBar";
import { ILanguage, KeyValue } from "modules/localization/models";
import TranslatableContentEditor from "modules/common/components/authoring/translatableContentEditor";
import { lcidMappings } from "modules/resources";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import TranslateContentConfirmation from "modules/common/components/authoring/dialogs/translateContentConfirmation";
import { ICustomCssModelv1 } from "api/files";

import "../styles/postMainPanel.sass";

interface IPostMainPanelProps {
    postId?: string;
    translations?: TranslatedContent;
    availableLanguages?: ILanguage[];
    selectedLanguages?: ILanguage[];
    activeLcid: string;
    defaultLcid: string;
    lcidMappings: lcidMappings;
    highlightedText?: string;
    fullscreenEditor?: boolean;
    fetchingAnalyses?: boolean;
    translation: TranslatableContent
    customCss?: ICustomCssModelv1;
    turnHighlightsOff?: () => void;
    onBodyFocus?: () => void;
    onFullscreen?: () => void;
    onRemoveLanguage: (lcid: string) => void;
    onSelectLanguage: (lcid: string) => void;
    getDraftTranslation: (id: string, content: TranslatableContent, outputLCID: string) => Promise<TranslatableContent>;
    onChangeBody: (newBody: string) => void;
    onChangeTitle: (newTitle: string) => void;
    onChangeSummary: (newSummary: string) => void;
    onChangeTranslatedContent:  (newValue: TranslatedContent) => void;
}

const CONFIRM_REPLACE_TRANSLATION = {
    text: <div style={{ width: 400 }}>Are you sure you want to replace the existing content?</div>,
    title: "Replace content",
};

/**
 * The post content editor panel
 * - language selector
 * - TranslatableContent editor
 * - this is an authoring v2 component
 */
const PostMainPanel: React.FunctionComponent<IPostMainPanelProps> = ({
    translations,
    postId,
    availableLanguages,
    selectedLanguages,
    activeLcid,
    defaultLcid,
    highlightedText,
    lcidMappings,
    fetchingAnalyses = false,
    translation,
    customCss,
    onBodyFocus,
    onFullscreen,
    turnHighlightsOff,
    onRemoveLanguage,
    onSelectLanguage,
    getDraftTranslation,
    onChangeTranslatedContent,
    onChangeBody,
    onChangeSummary,
    onChangeTitle,
}) => {
    const [removeLanguage, setRemoveLanguage] = useState<string | undefined>();
    const [showTranslationDialog, setShowTranslationDialog] = useState<boolean>(false);
    const [translatableLanguagesWithText, setTranslatableLanguagesWithText] = useState<ILanguage[]>([]);
    const [checkIfAnyTranslabableContent, setCheckIfAnyTranslabableContent] = useState<boolean>(false);

    useEffect(() => {
        function setLanguageTranslationAvailability() {
            let currentAvailableTranslations = { ...translations };
            let languagesWithText: ILanguage[] = [];
            let allAddedLanguages: ILanguage[] = [];

            Object.keys(currentAvailableTranslations).forEach((nextLanguage) => {
                if (availableLanguages && translations && translations[nextLanguage].body && translations[nextLanguage].title) {
                    let matchingLang = availableLanguages.find((l) => l.lcid === nextLanguage);

                    if (matchingLang) {
                        languagesWithText.push(matchingLang);
                    }
                }
                if (availableLanguages && translations) {
                    let matchingLang = availableLanguages.find((l) => l.lcid === nextLanguage);

                    if (matchingLang) {
                        allAddedLanguages.push(matchingLang);
                    }
                }
            });

            if (languagesWithText.length >= 1 && allAddedLanguages.length > 1) {
                setCheckIfAnyTranslabableContent(false);
            } else {
                setCheckIfAnyTranslabableContent(true);
            }

            setTranslatableLanguagesWithText(languagesWithText);
        }

        setLanguageTranslationAvailability();
    }, [translations, availableLanguages]);


    /**
     * Check if a translation has content
     * - if it does, show confirm dialog
     * - otherwise, continue remove
     */
    const onRemoveTranslation = (item: KeyValue) => {
        if (translationHasContent(item.key)) {
            setRemoveLanguage(item.key);
        } else {
            onConfirmRemoveLanguage(item.key);
        }
    };

    const removeTranslation = (lcid: string) => {
        // remove from translations
        let newTranslations = { ...translations };
        if (Object.keys(newTranslations).includes(lcid)) {
            delete newTranslations[lcid];
            onChangeTranslatedContent(newTranslations);
        }
    };

    /**
     * Select a translation
     * - if translation does not exist, add it
     */
    const onSelectTranslation = (item: KeyValue) => {
        onSelectLanguage(item.key);

        // add to translations
        let newTranslations = { ...translations };

        if (!Object.keys(newTranslations).includes(item.key)) {
            newTranslations[item.key] = DEFAULT_TRANSLATABLE_CONTENT;
            onChangeTranslatedContent(newTranslations);
        }
    };

    const translationHasContent = (lcid: string) =>
        translations &&
        translations[lcid] &&
        (Boolean(translations[lcid].title) || Boolean(translations[lcid].body) || Boolean(translations[lcid].description));

    /**
     * Handle translate click
     * - show dialog if translate will replace content
     */
    const onTranslate = async (inputLanguage: string) => {
        if (!translations || !inputLanguage) return;

        let translate = true;
        const ouputLanguage = availableLanguages?.find((language: ILanguage) => language.lcid === activeLcid);

        // show confirm dialog
        if (translationHasContent(activeLcid)) translate = await confirm.show(CONFIRM_REPLACE_TRANSLATION);

        // do the translation and update state
        if (translate && ouputLanguage) {
            // TODO: what is this id?
            const translation = await getDraftTranslation(
                postId || "00000000-0000-7368-6172-65706f696e74",
                translations[inputLanguage],
                ouputLanguage.translateAs
            );

            let newTranslations = { ...translations };
            newTranslations[activeLcid] = {
                ...translation,
                body: `<p><em>[${lcidMappings[activeLcid].autoTranslateText}]</em></p>${translation.body}`,
            };

            onChangeTranslatedContent(newTranslations);
        }
    };

    /**
     * Handle showing the translation dialog
     */
    const onShowTranslationDialog = (): void => {
        setShowTranslationDialog(true);
    };

    /**
     * Handle closing the translation dialog
     */
    const onCloseTranslationDialog = () => {
        setShowTranslationDialog(false);
    };

    /**
     * Handle dialog selection for the translation dialog
     */
    const onConfirmDialog = (inputLanguage: string) => {
        setShowTranslationDialog(false);
        onTranslate(inputLanguage);
    };

    /**
     * Handle confirm remove language or remove language with no content
     * @param lcidToRemove - used when no content (no confirm dialog)
     */
    const onConfirmRemoveLanguage = (lcidToRemove?: string) => {
        lcidToRemove = lcidToRemove || removeLanguage;

        if (lcidToRemove) {
            onRemoveLanguage(lcidToRemove); // prop function
            removeTranslation(lcidToRemove); // internal function
            setRemoveLanguage(undefined);
        }
    }

    return (
        <>
            <div className="post-main-panel">
                <LanguageBar
                    languages={availableLanguages?.map((lang) => ({ key: lang.lcid, value: lang.language })) || []}
                    activeLcid={activeLcid}
                    defaultLcid={defaultLcid}
                    selectedLanguages={selectedLanguages || []}
                    onAddLanguage={onSelectTranslation}
                    onRemoveLanguage={onRemoveTranslation}
                    onSelectLanguage={onSelectTranslation}
                    onTranslate={onShowTranslationDialog}
                    anyAvailableTranslationsCheck={checkIfAnyTranslabableContent}
                    hide={availableLanguages?.length === 1}
                    onOpen={() => {
                        if (turnHighlightsOff) turnHighlightsOff();
                    }}
                />
                <TranslatableContentEditor
                    customCss={customCss}
                    fetchingAnalyses={fetchingAnalyses}
                    activeLcid={activeLcid}
                    highlightedText={highlightedText}
                    onBodyFocus={onBodyFocus}
                    onFullscreen={onFullscreen}
                    onChangeBody={onChangeBody}
                    onChangeSummary={onChangeSummary}
                    onChangeTitle={onChangeTitle}
                    translation={translation}
                />
            </div>
            {showTranslationDialog && (
                <TranslateContentConfirmation
                    show={showTranslationDialog}
                    onConfirm={onConfirmDialog}
                    onCancel={onCloseTranslationDialog}
                    currentLanguagesWithText={translatableLanguagesWithText}
                />
            )}
            {removeLanguage && (
                <ConfirmDialog
                    title="You'll lose your translated content"
                    confirmLabel="KEEP"
                    denyLabel="REMOVE"
                    open={Boolean(removeLanguage)}
                    onClose={() => setRemoveLanguage(undefined)}
                    onConfirm={() => setRemoveLanguage(undefined)} // KEEP
                    onDeny={() => onConfirmRemoveLanguage()}
                >
                    <div style={{ width: 400 }}>
                        You have content on this language. Removing the language will clear the translated work you've done.
                        <br />
                        <br />
                        Do you want to continue?
                    </div>
                </ConfirmDialog>
            )}
        </>
    );
};

export default PostMainPanel;
