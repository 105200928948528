import * as React from "react";
import RequireRole from "modules/common/components/requireRole";
import { UserRoles } from "modules/authorization/models";
import DashboardTile from "./dashboardTile";
import { injectIntl, IntlShape } from "react-intl";
import AnalyticsErrorBanner from "modules/common/components/analyticsErrorBanner";
import { connect, ConnectedProps } from "react-redux"
import { GlobalApplicationState } from "globalApplicationState";

class Dashboard extends React.Component<PropsWithRedux, ComponentState> {
    public render() {
        const formatMessage = this.props.intl.formatMessage;
        return (
            <div className="dashboard">
                {this.props.bannerMessage &&
                    <span>
                        <AnalyticsErrorBanner mssg={this.props.bannerMessage} />
                        <div className="clearfix" />
                    </span>
                }
                <div className="dashboard-tiles">
                    <RequireRole roleName={UserRoles.Author}>
                        <DashboardTile materialIconName="description"
                            materialIconColor="#0247bf"
                            actionText={formatMessage({ id: "posts.posts" })}
                            linkTo="~/admin/posts" />
                    </RequireRole>
                    {this.props.eventsEnabled &&
                        <RequireRole roleName={UserRoles.Author}>
                            <DashboardTile materialIconName="calendar_today"
                                materialIconColor="#c48b3b"
                                actionText={formatMessage({ id: "events.events" })}
                                linkTo="~/admin/events" />
                        </RequireRole>
                    }
                    <RequireRole roleName={UserRoles.Author}>
                        <DashboardTile materialIconName="assignment_turned_in"
                            materialIconColor="#599fa0"
                            actionText={formatMessage({ id: "surveys.surveys" })}
                            linkTo="~/admin/surveys" />
                    </RequireRole>
                    {this.props.showFeatures && this.props.showFeatures.documentsEnabled &&
                        <RequireRole roleName={UserRoles.Author}>
                            <DashboardTile materialIconName="assignment"
                                materialIconColor="#3b6531"
                                actionText="Documents"
                                linkTo="~/admin/documents" />
                        </RequireRole>
                    }
                    {this.props.showFeatures && this.props.showFeatures.audiences &&
                        <RequireRole roleName={UserRoles.Owner}>
                            <DashboardTile materialIconName="supervisor_account"
                                materialIconColor="#e1cca9"
                                actionText={formatMessage({ id: "audiences.audiences" })}
                                linkTo="~/admin/audiences" />
                        </RequireRole>
                    }
                    <RequireRole roleName={UserRoles.Owner}>
                        <DashboardTile materialIconName="class"
                            materialIconColor="#453a6e"
                            actionText="Topics (Posts & Events)"
                            linkTo="~/admin/topics" />
                    </RequireRole>
                    {this.props.showFeatures && this.props.showFeatures.categoryTagsEnabled &&
                        <RequireRole roleName={UserRoles.Owner}>
                            <DashboardTile materialIconName="local_offer"
                                materialIconColor="#b291ba"
                                actionText="Category Tags (Others)"
                                linkTo="~/admin/categoryTags" />
                        </RequireRole>
                    }
                    <RequireRole roleName={UserRoles.Owner}>
                        <DashboardTile materialIconName="send"
                            materialIconColor="#69a2d1"
                            actionText={formatMessage({ id: "messaging.directMessaging" })}
                            linkTo="~/admin/messaging" />
                    </RequireRole>
                    <RequireRole roleName={UserRoles.Owner}>
                        <DashboardTile materialIconName="newspaper"
                            materialIconColor="#897d6c"
                            actionText={formatMessage({ id: "messaging.newsletters" })}
                            linkTo="~/admin/newsletters" />
                    </RequireRole>
                    {this.props.showFeatures && this.props.showFeatures.portalPagesEnabled && this.props.portalPagesEnabled &&
                        <RequireRole roleName={UserRoles.Owner}>
                            <DashboardTile materialIconName="menu_book"
                                materialIconColor="#ad392f"
                                actionText="Portal Pages"
                                linkTo="~/admin/portalPages" />
                        </RequireRole>
                    }
                    <RequireRole roleName={UserRoles.Author}>
                        <DashboardTile materialIconName="photo_library"
                            materialIconColor="#395e9e"
                            actionText="Media Gallery"
                            linkTo="~/admin/gallery" />
                    </RequireRole>
                    <RequireRole roleName={UserRoles.Owner}>
                        <DashboardTile materialIconName="history"
                            materialIconColor="#f5bf42"
                            actionText="Activity Tools"
                            linkTo="~/admin/activity" />
                    </RequireRole>
                    <RequireRole roleName={UserRoles.Author}>
                        <DashboardTile materialIconName="insert_chart"
                            materialIconColor="#81cc78"
                            actionText="Platform Analytics"
                            linkTo="~/admin/insights" />
                    </RequireRole>
                    <RequireRole roleName={UserRoles.Owner}>
                        <DashboardTile materialIconName="account_circle"
                            materialIconColor="#ad5774"
                            actionText="Users & Permissions"
                            linkTo="~/admin/users" />
                    </RequireRole>
                    <RequireRole roleName={UserRoles.Owner}>
                        <DashboardTile materialIconName="settings"
                            materialIconColor="#adb7c1"
                            actionText="Tenant Configurations"
                            linkTo="~/admin/settings" />
                    </RequireRole>
                    <RequireRole roleName={UserRoles.SparrowSuperAdmin}>
                        <DashboardTile materialIconName="admin_panel_settings"
                            materialIconColor="#ad392f"
                            actionText="Tenant Management"
                            linkTo="~/admin/tenantManagement"  />
                    </RequireRole>
                </div>

            </div>
        );
    }
}


interface ComponentProps {
    intl: IntlShape;
}

interface ComponentState {
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        androidAppUrl: state.settings.tenantSettings.androidAppUrl,
        bannerMessage: state.settings.tenantSettings.bannerMessage,
        documentsEnabled: state.settings.tenantSettings.showFeatures.documentsEnabled && state.settings.tenantSettings.documentsEnabled,
        eventsEnabled: state.settings.tenantSettings.mobileEventsEnabled,
        iosAppUrl: state.settings.tenantSettings.iosAppUrl,
        showFeatures: state.settings.tenantSettings.showFeatures,
        portalPagesEnabled: state.settings.tenantSettings.showFeatures.portalPagesEnabled && state.settings.tenantSettings.portalPagesEnabled
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(Dashboard))
