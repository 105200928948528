import React, { useEffect, useState } from "react";

import Callout from "modules/common/components/callout";
import Loading from "modules/common/components/loading";

import { PostFeedItem } from "modules/posts/models";

import TextField from "@mui/material/TextField";

import SearchIcon from "@mui/icons-material/Search";

import moment from "moment";


const SearchPosts: React.FunctionComponent<ComponentProps> = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState([] as PostFeedItem[]);
  const [searchText, setSearchText] = useState("");
  const [showCallout, setShowCallout] = useState(false);

  useEffect(() => {
    moment.locale("en");
  }, []);

  return (
    <React.Fragment>
      <TextField
        variant="outlined"
        size="small"
        value={searchText}
        placeholder="Search post"
        autoComplete="off"
        InputProps={{
          startAdornment: <SearchIcon className="search-icon" />
        }}
        onChange={(ev: any) => {
          setSearchText(ev.target.value);
          setAnchorEl(ev.currentTarget);
        }}
        onKeyUp={(key) => {
          if (key.keyCode === 13 && !!searchText) {
            setIsSearching(true);
            setResults([]);
            setShowCallout(true);
            props.onSearch(searchText).then((feed) => {
              setResults(feed);
              setIsSearching(false);
            });
          }
        }}
        className="search-text"
      />
      <Callout
        anchorEl={showCallout ? anchorEl : null}
        open={showCallout}
        setOpen={setShowCallout}
      >
        <div className="newsletter-search-results-callout">
          {isSearching
            ? <Loading padding={12} />
            : !results.length
                ? <div>No matching results found</div>
                : <div>
                    {results.map((result) =>
                      <div
                        key={result.id}
                        onClick={() => {
                          props.onPin(result);
                          setAnchorEl(null);
                          setSearchText("");
                          setShowCallout(false);
                        }}
                        className="search-result"
                      >
                        <div className="search-image">
                          <img src={result.imageUrl} alt="" />
                        </div>
                        <div className="search-content">
                          <div className="title">{result.title}</div>
                          <div className="author">{result.author.name}</div>
                          <div className="time">Published {moment(result.publishTime).format("MMM D, YYYY")}</div>
                        </div>
                      </div>
                    )}
                  </div>
          }
        </div>
      </Callout>
    </React.Fragment>
  );
}

interface ComponentProps {
  onPin: (post: PostFeedItem) => void;
  onSearch: (searchText: string) => Promise<PostFeedItem[]>;
}

export default SearchPosts;