import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom";
import { push } from "react-router-redux";
import { injectIntl, IntlShape } from "react-intl";
import moment from "moment";
import {
    useMediaQuery,
    useTheme,
    Button,
    Hidden,
    List,
    ListItem,
    ListItemText,
    FormControlLabel,
    Checkbox,
    IconButton,
    Typography,
} from "@mui/material";
import { PickerLocalization } from "modules/common/components/pickerLocalization";
import MoreVertIcon from '@mui/icons-material/MoreVert';

import * as postActions from "modules/posts/actionCreator";
import * as settingsActions from "modules/settings/actionCreator";
import * as userActions from "modules/profile/actionCreator";
import { BackItem } from "pages/common/breadcrumb";
import AuthoringAppBar from "modules/common/components/authoring/authoringAppBar";
import useIsMounted from "modules/common/hooks/useIsMounted";
import * as RemoteFocusRequests from "modules/common/remoteFocusRequest";
import Loading from "modules/common/components/loading";
import { GlobalApplicationState } from "globalApplicationState";
import BasePage from "pages/common/basePage";
import { NotificationRange, POST_TYPES, Post, PostOverview } from "../../models";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import PostEditor from "./postEditor";
import PostContentView from "../post-view/postContentView";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import CalloutHover from "modules/common/components/hovers/calloutHover";
import Preview from "modules/common/components/authoring/dialogs/preview";
import { Tab as ITab } from "pages/common/tabs";
import OneLastCheck from "modules/common/components/authoring/dialogs/oneLastCheck";
import useQueryParams, { QUERY_PARAM_KEYS } from "modules/common/hooks/useQueryParams";
import { draftPostsApi, loggedEventsApi, submissionPostsApi } from "api/instances";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";
import useIsFirstRender from "modules/common/hooks/useIsFirstRender";
import { useCustomTinyMceCssContent } from "modules/common/hooks/data/useCustomTinyMceCssContent";

import "modules/common/components/authoring/authoring.sass";
import "modules/common/components/authoring/styles/authoringLayout.sass"
import "./postCreation.sass";
import "styles/views.sass";
import "modules/posts/components/post-view/postView.sass";
import "modules/posts/components/post-view/reactions/reactions.sass";
import Callout from "modules/common/components/callout";
import { UserRoles } from "modules/authorization/models";
import { getHighestRole } from "utils/userRoleUtils";
import MoreOptionsButton from "modules/common/components/buttons/moreOptionsButton";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import { openPostInPortal } from "utils/redirectUtils";
import { 
    getDefaultNotificationSetting,
    isAnyTenantPostNotificationsEnabled 
} from "utils/notificationsHelper";
import { NotificationEvents } from "modules/messaging";
import { ConfirmRepublishCompliancePostDialog } from "modules/common/components/dialogs/confirmRepublishCompliancePostDialog";

export const POST_SETTINGS_EDITOR_DRAWER_WIDTH = 469; // match with authoringLayout.sass $settings-drawer-width variable

export enum POST_SETTINGS_TAB_VALUES {
    POST_DETAILS,
    POST_OPTIONS,
    CONTENT_ANALYSES
}

const TABS: ITab[] = [
    {
        label: "Post details",
        value: POST_SETTINGS_TAB_VALUES.POST_DETAILS,
    },
    {
        label: "Post options",
        value: POST_SETTINGS_TAB_VALUES.POST_OPTIONS,
    },
    {
        label: "Conent analyses",
        value: POST_SETTINGS_TAB_VALUES.CONTENT_ANALYSES,
    }
];

const PostCreation: React.FunctionComponent<PropsWithRedux> = ({
    currentUser,
    notificationSettings,
    match,
    tagSettings,
    tenantSettings,
    isValid,
    post,
    tenantId,
    getTagSettings,
    setNewPost,
    fetchDraftPost,
    ...props
}) => {
    const { userId } = currentUser;
    const { params: { draftId } } = match;
    const isDraftIdPresent = React.useMemo(() => draftId && draftId !== "new", [draftId]);

    const openedTimeStamp = useRef<string>(new Date().toISOString());
    const isMounted = useIsMounted();
    const isFirstRender = useIsFirstRender();
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const queryParams = useQueryParams();
    const history = useHistory();
    const { customCss, fetching: fetchingCustomCss } = useCustomTinyMceCssContent(tenantSettings.showFeatures.tinyMceCustomCssEnabled);
    const isContributor = getHighestRole(currentUser) === UserRoles.Contributor;
    const isSubmission = post.isSubmission !== undefined && post.isSubmission;
    const isSubmissionMode = (isContributor && !isDraftIdPresent) || isSubmission;
    const draftBtnText = isContributor && isSubmission ? "Move To Drafts" : "Save As Draft";
    const saveSubmissionBtnText = "Save Changes";
    const reqRevSubmissionBtnText = "Require Revisions";
    const isTenantNotifsEnabled = isAnyTenantPostNotificationsEnabled(notificationSettings);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [oneLastCheckOpen, setOneLastCheckOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [calloutOpen, setCalloutOpen] = useState(false);
    const [settingsDrawerOpen, setSettingsDrawerOpen] = useState<boolean>(!isSmallAndSmaller);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewPost, setPreviewPost] = useState<PostOverview | undefined>(); // for preview page and one last check modal
    const [confirmAnalyticsDialogOpen, setConfirmAnalyticsDialogOpen] = useState<boolean>(false); // content analyses publish confirm dialog
    const [unsavedChangesWarning, setUnsavedChangesWarning] = useState<boolean>(false);
    const [confirmDiscardPost, setConfirmDiscardPost] = useState<boolean>(false);
    const [confirmRequireRevision, setConfirmRequireRevision] = useState<boolean>(false);
    const [selectedSettingsTab, setSelectedSettingTab] = useState<number>(TABS[0].value);
    const [dontShowConfirmDialogAgainChecked, setDontShowConfirmDialogAgainChecked] = useState<boolean>(!currentUser.showContentAnalysesDialog);
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [fullscreenEditor, setFullscreenEditor] = useState<boolean>(false);
    const [analysisIndicator, setAnalysisIndicator] = useState<boolean>(false);
    const [confirmRepublishComplianceOpen, setConfirmRepublishComplianceOpen] = useState<boolean>(false);

    const getNewPost = useCallback(() => {
        const author: string = currentUser ? (currentUser.preferredName ? `${currentUser.preferredName} ${currentUser.lastName}` : `${currentUser.firstName} ${currentUser.lastName}`) : "";
        const authorEmail: string = currentUser ? currentUser.email : "";
        const lcid: string = tenantSettings ? tenantSettings.defaultLCID : "en-us";

        const post: Partial<Post> = {
            id: "",
            attachedContent: [],
            author: author,
            authorEmail: authorEmail,
            commentingEnabled: tenantSettings.isCommentingEnabledForNewPostsByDefault,
            commentingType: "standard",
            fileAttachments: [],
            image: {
                id: "",
                url: "",
                transforms: {
                    points: [],
                    zoom: 1
                }
            },
            notifications: {
                emailOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedEmail, notificationSettings),
                mobileOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedMobile, notificationSettings),
                reminders: isTenantNotifsEnabled ?
                    notificationSettings.defaultPostSettings?.reminders || []: 
                    [],
                smsOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedSMS, notificationSettings),
                teamsOnPublish: getDefaultNotificationSetting(NotificationEvents.PostPublishedTeams, notificationSettings)
            },
            postType: "standard",
            reactingEnabled: tenantSettings.isReactingEnabledForNewPostsByDefault,
            tags: [],
            translatedContent: {
                [lcid]: {
                    body: "",
                    description: "",
                    title: ""
                }
            },
        };

        return post;
    }, [currentUser, notificationSettings.defaultPostSettings, tenantSettings]);

    const setupPreviewPost = React.useCallback(async (id?: string) => {
        const idToUse = post.id || id;
        if (!idToUse) return;

        try {
            // using api fetch instead of redux because redux clears the post from editor state
            const postOverview = await draftPostsApi.getDraftPost(idToUse);
            if (isMounted()) {
                setPreviewOpen(true);
                setPreviewPost({
                    ...postOverview,
                    author: { name: postOverview.author, email: postOverview.authorEmail, avatar: { color: "#2196f3" } },
                    imageUrl: postOverview.image.url,
                });
            }
        } catch (err) { }
    }, [post.id, isMounted]);

    const sendPostAuthoringClosedSparrow = React.useCallback(async () => {
        try {
            if (isDraftIdPresent && userId)
                await loggedEventsApi.sendPostAuthoringClosedEvent(tenantId, draftId, userId);
        } catch (err) { }
    }, [isDraftIdPresent, userId, tenantId, draftId]);

    const sendPostAuthoringClosedEventBeacon = React.useCallback(async () => {
        try {
            if (isDraftIdPresent && userId && tenantId) {
                await loggedEventsApi.sendPostAuthoringClosedEventBeacon(tenantId, draftId, userId);
            }
        } catch (err) { }
    }, [draftId, tenantId, userId, isDraftIdPresent]);

    const sendPostAuthoringOpenedEvent = React.useCallback(async (time?: string) => {
        try {
            if (isDraftIdPresent && userId)
                await loggedEventsApi.sendPostAuthoringOpenedEvent(draftId, time || openedTimeStamp.current);
        } catch (err) { }
    }, [userId, isDraftIdPresent, draftId]);

    // on page visibilitychange send post opened or post closed depending on document.visibilityState
    useEffect(() => {
        /**
         * Send post authoring depending on visibility state
         * - this is the recommended way to  send any analytics on user session end of a page
         * - more info here: https://developer.chrome.com/blog/page-lifecycle-api/
        */
        const onVisbilityChange = async () => {
            if (document.visibilityState === "hidden")
                await sendPostAuthoringClosedEventBeacon();
            else if (document.visibilityState === "visible")
                await sendPostAuthoringOpenedEvent(new Date().toISOString());
        }

        document.addEventListener("visibilitychange", onVisbilityChange);

        return () => {
            document.removeEventListener("visibilitychange", onVisbilityChange);
        }
    }, [sendPostAuthoringClosedEventBeacon, sendPostAuthoringOpenedEvent]);

    // send post authoring open event when post has id
    useEffect(() => {
        sendPostAuthoringOpenedEvent(openedTimeStamp.current);
    }, [userId, isDraftIdPresent, draftId, sendPostAuthoringOpenedEvent]);

    // post authoring close event
    // - this will handle the navigations within our app
    useEffect(() => {
        return () => {
            sendPostAuthoringClosedSparrow();
        }
    }, [sendPostAuthoringClosedSparrow]);

    /**
     * Handle preview query param effects
     */
    useEffect(() => {
        if (queryParams.get(QUERY_PARAM_KEYS.LOAD_PREVIEW_OPEN) === "true" && match.params.draftId !== "new") {
            setupPreviewPost(match.params.draftId);

            // remove query param
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.delete(QUERY_PARAM_KEYS.LOAD_PREVIEW_OPEN);
            history.replace({
                search: urlParams.toString()
            });
        }
    }, [isFirstRender, queryParams, match.params.draftId, history, setupPreviewPost]);

    // effects of post changes
    useEffect(() => {
        moment.locale("en");

        const loadExistingPost = async () => {
            // redux fetch
            await fetchDraftPost(match.params.draftId);
            if (isMounted())
                setIsLoading(false);
        }

        if (!isDraftIdPresent) {
            const post = { ...getNewPost() };
            setNewPost(post);
            setIsLoading(false);
        } else if (isFirstRender) {
            loadExistingPost();
        }
    }, [getNewPost, isMounted, isFirstRender, isDraftIdPresent, setNewPost, match.params.draftId, fetchDraftPost]);

    // effects of tag settings change
    useEffect(() => {
        if (tagSettings.shouldFetch || !tagSettings.tagGroups.length)
            getTagSettings();
    }, [getTagSettings, tagSettings.shouldFetch, tagSettings.tagGroups.length]);

    const backToManagePosts = () => {
        if (props.changedSinceSaved)
            setUnsavedChangesWarning(true);
        else
            props.redirectTo(`/${match.params.tenant}/admin/posts`);
    }

    /**
     * Save post
     * @param draftSaveOnly     flag whether we are only saving as a draft - controls whether we show a snackbar or not
     * @param preview           preview query param for redirect
     * @param redirectTo        url to redirect to after saving existing post
     * @param isSubmission      flag if we want the post to be saved as a submission or not. If false, post is saved as a draft.
     * @returns boolean on whether we redirect or not
     */
    const savePost = async (
        draftSaveOnly: boolean = true,
        preview: boolean = false,
        redirectTo: string = "",
        isSubmission: boolean = false,
    ): Promise<boolean> => {
        let success = false;
        try {
            if (!(props.loading || props.saving || props.publishing)) {
                if (!isDraftIdPresent) {
                    const draftId = await props.createNew();
                    props.updateId(draftId);
                    success = await props.save(isSubmission);

                    props.redirectTo(`/${match.params.tenant}/admin/posts/edit/${draftId}?preview=${preview}`);

                    return true;

                } else {
                    success = await props.save(isSubmission);
                }
            }
        } catch (error) {
        }
        finally {
            if (success && draftSaveOnly)
                onSaveDraftSuccess();

            if (redirectTo) {
                props.redirectTo(redirectTo);
                return true;
            }

            return false;
        }
    }

    const saveRevSubmission = async () => {
        // if the post has been changed since last saved, give user option to save or discard changes
        return props.changedSinceSaved
            ? setConfirmRequireRevision(true)
            : await unsubmitPost(match.params.draftId);
    }

    const onSaveDraftSuccess = () => {
        if (isMounted())
            setSuccessMessage("Successfully saved as draft.");
    }

    const discardPost = () => {
        if (props.changedSinceSaved) {
            setConfirmDiscardPost(true);
        }
    }

    const publishPost = async () => {
        // show confirm dialog for re-publishing compliance posts
        if (post.postType === POST_TYPES.COMPLIANCE && post.isPreviouslyPublished) {
            setConfirmRepublishComplianceOpen(true);
        } else {
            await publishPostConfirm();
        }
    }

    const publishPostConfirm = async () => {
        if (props.changedSinceSaved)
            await savePost(false, false);

        // show confirm dailog for seeing available content analyses
        let getPreviewablePost = false;
        if (currentUser.showContentAnalysesDialog && analysisIndicator) {
            setConfirmAnalyticsDialogOpen(true);
        } else {
            getPreviewablePost = true;
            setOneLastCheckOpen(true);
        }

        if (!post.id) return undefined;

        // using api fetch instead of redux because redux clears the post from editor state
        // before then fetching which results in blank inputs for a sec
        const postOverview = getPreviewablePost ? await draftPostsApi.getDraftPost(post.id) : undefined;

        if (postOverview && isMounted())
            setPreviewPost({
                ...postOverview,
                author: { name: postOverview.author, email: postOverview.authorEmail, avatar: { color: "#2196f3" } },
                imageUrl: postOverview.image.url,
            });
    }

    const unsubmitPost = async (postId: string) => {
        try {
            props.setSaving(true);
            await submissionPostsApi.unsubmitSubmissionPost(postId);
        }
        catch (error) { }
        finally {
            props.setSaving(false);
            props.redirectTo(`/${match.params.tenant}/admin/posts`);
            props.setChangedSinceSaved(false);
        }
    };

    const publishConfirmed = async (): Promise<void> => {
        let date = post.publishTime
            ? new Date(post.publishTime)
            : new Date();

        isContributor
            ? await props.submitPost(match.params.draftId)
            : await props.publishPost(match.params.draftId, date.toISOString());
    };

    const onShowTabCommandList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    /**
     * Set settings drawer open to a value or toggle it by leaving parameter undefined
     * @param open
     */
    const onToggleSettingsDrawer = useCallback((open: boolean | undefined): void => {
        if (!fullscreenEditor) {
            // toggle or set as parameter "open"
            setSettingsDrawerOpen(prev => {
                let newValue = !prev;
                if (open !== undefined)
                    newValue = open;

                return newValue;
            });
        }
    }, [fullscreenEditor, setSettingsDrawerOpen]);

    const onPreview = async () => {
        try {
            // save post - this will redirect if first save
            // otherwise, open previewer
            let redirecting = false;
            if (props.changedSinceSaved)
                redirecting = await savePost(false, true);

            // grab draft - TODO: merge our post models (PostOverview/Post etc) so we don't have to go to api here
            if (!redirecting)
                await setupPreviewPost();
        } catch (error) { }
    }

    /**
     * Continue with publish
     * - try save dont show confirm next time
     * - grab post overview model
     * - show one last check
     */
    const onDenyAnalyticsDialog = async () => {
        setConfirmAnalyticsDialogOpen(false);

        await updateUserSettings();

        try {
            if (!post.id) return;

            // using api fetch instead of redux because redux clears the post from editor state
            let postOverview = await draftPostsApi.getDraftPost(post.id);
            if (isMounted())
                setPreviewPost({
                    ...postOverview,
                    author: { name: postOverview.author, email: postOverview.authorEmail, avatar: { color: "#2196f3" } },
                    imageUrl: postOverview.image.url,
                });
        } catch (err) { }
        finally {
            if (isMounted())
                setOneLastCheckOpen(true);
        }
    }

    /**
     * Open up content analyses tab
     */
    const onConfirmAnalyticsDialog = async () => {
        setConfirmAnalyticsDialogOpen(false);
        setSettingsDrawerOpen(true);
        setSelectedSettingTab(POST_SETTINGS_TAB_VALUES.CONTENT_ANALYSES);

        await updateUserSettings();
    }

    const onChangeSettingsTab = async (event: React.ChangeEvent, newValue: number) => {
        setSelectedSettingTab(newValue);
    }

    const updateUserSettings = async (): Promise<boolean> => {
        if (currentUser.showContentAnalysesDialog !== dontShowConfirmDialogAgainChecked) return true;

        let res = await props.saveUserSettings({ ...currentUser, showContentAnalysesDialog: !dontShowConfirmDialogAgainChecked });

        return res;
    }

    const getPublishBtnName = (): string => {
        if (!isContributor) return isSubmission ? "Approve & Publish" : "Publish";

        if (isDraftIdPresent && isSubmission) return "Resubmit";

        return "Submit";
    }

    const onChangeAnalysisIndicator = (value: boolean) => {
        setAnalysisIndicator(value);
    }

    /**
     * Helper to get the buttons for the top bar
     * @returns buttons
     */
    const getCommands = (): JSX.Element => {
        return (
            <>
                <Hidden mdUp>
                    <div className="post-tab-command">
                        <IconButton color="primary" size="small" onClick={onShowTabCommandList}>
                            <MoreVertIcon />
                        </IconButton>
                        <Button variant="contained" color="primary" disabled={!isValid} onClick={async () => await publishPost()}>
                            {getPublishBtnName()}
                        </Button>
                        <Callout popoverProps={{
                            anchorOrigin: {
                                vertical: 0,
                                horizontal: 450
                            }
                        }} contentClassName="post-tab-command-callout" arrowPosition="none" anchorEl={anchorEl} open={calloutOpen} setOpen={setCalloutOpen}>
                            <List className="post-tab-command-list">
                                <ListItem button onClick={() => {
                                    setAnchorEl(null); // close callout
                                    backToManagePosts();
                                }}>
                                    <ListItemText primary="Back to Manage Posts" />
                                </ListItem>
                                <ListItem disabled button>
                                    <ListItemText primary="Preview (Desktop only)" />
                                </ListItem>
                                <ListItem button disabled={!post.id} onClick={() => {
                                    setAnchorEl(null); // close callout
                                    discardPost();
                                }}>
                                    <ListItemText primary="Discard" />
                                </ListItem>
                                {
                                    isSubmission && !isContributor ?
                                        <>
                                            <ListItem
                                                button
                                                disabled={!props.changedSinceSaved}
                                                onClick={async () => await savePost(false, false, `/${match.params.tenant}/admin/posts`, true)}
                                            >
                                                <ListItemText primary={saveSubmissionBtnText} className="tab-command-list-item" />
                                            </ListItem>
                                            <ListItem
                                                button
                                                disabled={!props.changedSinceSaved}
                                                onClick={async () => await saveRevSubmission()}
                                            >
                                                <ListItemText primary={reqRevSubmissionBtnText} className="tab-command-list-item" />
                                            </ListItem>
                                        </>
                                        :
                                        <ListItem button disabled={!props.changedSinceSaved} onClick={async () => {
                                            setAnchorEl(null); // close callout
                                            await savePost(true, false);
                                        }}>
                                            <ListItemText primary={draftBtnText} className="tab-command-list-item" />
                                        </ListItem>
                                }
                            </List>
                        </Callout>
                    </div>
                </Hidden>
                <Hidden smDown>
                    {
                        isSubmissionMode &&
                        <div className="needs-review-div">
                            <span className="needs-review-dot" />
                            <Typography className="needs-review-text" fontSize={"15px"}>
                                {isContributor ? 'Needs Review' : 'Submission'}
                            </Typography>
                        </div>
                    }
                    <Button style={{
                        backgroundColor: "transparent"
                    }}
                        className="preview-button"
                        variant="text"
                        onClick={onPreview}
                        color="primary"
                    >
                        PREVIEW
                    </Button>
                    <Button className="discard-button" variant="text" disabled={!post.id} onClick={discardPost}>Discard</Button>
                    {
                        isSubmission && !isContributor ?
                            <MoreOptionsButton
                                text="Save Options"
                                className="moreOptionsButton"
                                divStyle={{ marginRight: "18px" }}
                            >
                                <List disablePadding>
                                    <MoreOptionsItem
                                        text={saveSubmissionBtnText}
                                        onClick={async () => await savePost(false, false, `/${match.params.tenant}/admin/posts`, true)}
                                    />
                                    <MoreOptionsItem
                                        text={reqRevSubmissionBtnText}
                                        onClick={async () => await saveRevSubmission()}
                                    />
                                </List>
                            </MoreOptionsButton>
                            :
                            <Button
                                className="save-as-draft-button"
                                variant="outlined"
                                color="primary"
                                disabled={!props.changedSinceSaved}
                                onClick={async () => await savePost(true, false)}
                            >
                                {draftBtnText}
                            </Button>
                    }
                    {/* add hover helper when settings is closed and there are required fields missing */}
                    {!settingsDrawerOpen && !isValid
                        ? <CalloutHover
                            component={
                                <Button variant="contained" color="primary" disabled={!isValid} onClick={async () => await publishPost()}>{getPublishBtnName()}</Button>}
                        >
                            Complete the required fields to publish.
                        </CalloutHover>
                        : <Button variant="contained" color="primary" disabled={!isValid} onClick={async () => await publishPost()}>{getPublishBtnName()}</Button>}
                </Hidden>
            </>
        );
    }

    const getDesktopPreview = () => (
        <div className="post-preview">
            {previewPost &&
                <PostContentView
                    tinyMceCustomCssEnabled={tenantSettings.showFeatures.tinyMceCustomCssEnabled}
                    reactions={tenantSettings.reactions.filter((t) => t.enabled).map((t) => ({ ...t, order: t.sortIndex }))}
                    reactionsEnabledOnTenant={tenantSettings.reactionsEnabled}
                    commentsEnabledOnTenant={tenantSettings.commentsEnabled}
                    post={previewPost}
                    lcidMappings={props.lcidMappings}
                    defaultLcid={props.defaultLang}
                />}
        </div>
    );

    const getBackToManagePostsConfirm = (): JSX.Element => (
        <ConfirmDialog
            title="Back to Manage Posts"
            confirmLabel="KEEP EDITING" // this is backwards on purpose
            denyLabel="EXIT"
            open={unsavedChangesWarning}
            onClose={() => setUnsavedChangesWarning(false)}
            onConfirm={() => setUnsavedChangesWarning(false)}
            onDeny={() => {
                props.redirectTo(`/${match.params.tenant}/admin/posts`);
            }}
        >
            <div style={{ minWidth: 400 }}>
                <div>
                    You have unsaved changes to your post.</div>
                <br />
                <div>Do you want to continue?</div>
            </div>
        </ConfirmDialog>
    );

    const getContentAnalysesConfirm = (): JSX.Element => (
        <ConfirmDialog
            title="Boost your readership and engagement!"
            confirmLabel="VIEW ANALYSIS"
            denyLabel={`CONTINUE TO ${getPublishBtnName().toUpperCase()}`}
            open={confirmAnalyticsDialogOpen}
            onClose={() => setConfirmAnalyticsDialogOpen(false)}
            onConfirm={onConfirmAnalyticsDialog}
            onDeny={onDenyAnalyticsDialog}
        >
            <>
                We analyzed your content and found recommendations that can help you improve its reach and engagement.
                <br />
                <br />
                Do you want to see the content analysis?
                <br />
                <br />
                <FormControlLabel
                    classes={{
                        label: "font-14"
                    }}
                    label="Don't show this again"
                    control={<Checkbox size="small" color="primary" checked={dontShowConfirmDialogAgainChecked} onChange={(_, checked) => setDontShowConfirmDialogAgainChecked(checked)} />}
                />
            </>
        </ConfirmDialog>
    );

    const getDiscardPostConfirm = (): JSX.Element => (
        <ConfirmDialog
            title="Discard Post"
            confirmLabel="SAVE CHANGES"
            denyLabel="DISCARD"
            open={confirmDiscardPost}
            onClose={() => setConfirmDiscardPost(false)}
            onConfirm={async () => {
                setConfirmDiscardPost(false);
                await savePost(false, false, `/${match.params.tenant}/admin/posts`);
            }}
            onDeny={() => {
                setConfirmDiscardPost(false);
                props.setChangedSinceSaved(false);
                props.redirectTo(`/${match.params.tenant}/admin/posts`);
            }}
        >
            You have unsaved changes to your draft. Would you like to save these changes before exiting?
        </ConfirmDialog>
    );

    const getRequireRevisionConfirm = (): JSX.Element => (
        <ConfirmDialog
            title="Require Revisions"
            confirmLabel="SAVE CHANGES"
            denyLabel="MOVE WITHOUT CHANGES"
            open={confirmRequireRevision}
            onClose={() => setConfirmRequireRevision(false)}
            onConfirm={async () => {
                setConfirmRequireRevision(false);
                await unsubmitPost(match.params.draftId);
                await savePost(false, false, `/${match.params.tenant}/admin/posts`);
                props.setChangedSinceSaved(false);
            }}
            onDeny={async () => {
                setConfirmRequireRevision(false);
                props.setChangedSinceSaved(false);
                await unsubmitPost(match.params.draftId);
            }}
        >
            You made changes to this submission. Do you want to save them before sending back for revision?
        </ConfirmDialog>
    );

    const getConfirmRepublishCompliancePost = (): JSX.Element =>
        <ConfirmRepublishCompliancePostDialog
            open={confirmRepublishComplianceOpen}
            onClose={() => setConfirmRepublishComplianceOpen(false)}
            onDeny={() => setConfirmRepublishComplianceOpen(false)}
            onConfirm={async () => {
                setConfirmRepublishComplianceOpen(false);
                await publishPostConfirm();
            }}
        />;

    const getPreviewOpenAction = (): (() => void) | undefined => {
        return previewPost?.previouslyPublishedPostId ?
            () => openPostInPortal(tenantId, previewPost.previouslyPublishedPostId) :
            undefined;
    };

    const getPreviewPublishLabel = (): string | undefined => {
        if (!previewPost) return;

        let publishLabel: string | undefined;

        if (isContributor && isSubmission)
            publishLabel = "Resubmit";
        else if (isContributor)
            publishLabel = "Submit";
        else if (!isContributor && previewPost.isSubmission)
            publishLabel = "Approve & Publish";
        else if (!isContributor && !previewPost.isSubmission)
            publishLabel = "Publish";

        return publishLabel;
    };

    return (
        <>
            {isLoading || fetchingCustomCss
                ? <Loading />
                : <BasePage fullWidth>
                    <RemoteFocusRequests.Component />
                    <AuthoringAppBar
                        backAction={
                            // back to manage posts goes into button command list (getCommands()) on small screens
                            isSmallAndSmaller
                                ? <></>
                                : <BackItem textStyle={{ fontWeight: 500 }} title="Back to Manage Posts"
                                    onClick={backToManagePosts}
                                />
                        }
                        actions={getCommands()}
                        isSubmissionMode={isSubmissionMode}
                    />
                    <>
                        <div className="authoring-page" style={{ height: "100%", marginTop: 52 }}>
                            <PickerLocalization>
                                <PostEditor
                                    customCss={customCss}
                                    fullscreenEditor={fullscreenEditor}
                                    onChangeFullscreen={() => {
                                        setFullscreenEditor(prev => !prev);
                                        setSettingsDrawerOpen(false);
                                    }}
                                    selectedTab={selectedSettingsTab}
                                    onChangeTab={onChangeSettingsTab}
                                    onToggleSettingsDrawer={onToggleSettingsDrawer}
                                    settingsDrawerOpen={settingsDrawerOpen}
                                    setSettingsDrawer={(open: boolean) => {
                                        setSettingsDrawerOpen(open);
                                    }}
                                    isContributor={isContributor}
                                    analysisIndicator={analysisIndicator}
                                    onChangeAnalysisIndicator={onChangeAnalysisIndicator}
                                    isTenantNotifsEnabled={isTenantNotifsEnabled}
                                />
                            </PickerLocalization>
                            <LoadingOverlay show={props.loading || props.saving || props.publishing} />
                            <div className="clearfix"></div>
                        </div>
                    </>
                </BasePage>}
            <SuccessSnackbar
                successMessage={successMessage || ""}
                clearSuccessMessage={() => setSuccessMessage("")}
            />
            <OneLastCheck
                tags={post.tags?.map((tag) => tag.id)}
                open={oneLastCheckOpen}
                publishTime={post.publishTime}
                expiryTime={post.expiryTime}
                featuredTime={post.featuredTime}
                breakingTime={post.breakingTime}
                tenantSettings={tenantSettings}
                tenantNotificationSettings={notificationSettings}
                onClose={() => setOneLastCheckOpen(false)}
                onPublish={publishConfirmed}
                notifications={post.notifications}
                commentsEnabledOnContent={post.commentingEnabled}
                reactionsEnabledOnContent={post.reactingEnabled}
                commentType={post.commentingType}
                isPreviouslyPublished={post.isPreviouslyPublished}
                disableKeepEditing={props.publishing}
                isContributor={isContributor}
                publishBtnName={getPublishBtnName()}
            >
                <div className="post-preview">
                    {previewPost &&
                        <PostContentView
                            tinyMceCustomCssEnabled={tenantSettings.showFeatures.tinyMceCustomCssEnabled}
                            showComments={false}
                            showReactions={false}
                            reactions={tenantSettings.reactions.filter((t) => t.enabled).map((t) => ({ ...t, order: t.sortIndex }))}
                            reactionsEnabledOnTenant={tenantSettings.reactionsEnabled}
                            commentsEnabledOnTenant={tenantSettings.commentsEnabled}
                            post={previewPost}
                            lcidMappings={props.lcidMappings}
                            defaultLcid={props.defaultLang}
                        />}
                </div>
            </OneLastCheck>
            <Preview
                open={previewOpen}
                onClose={() => setPreviewOpen(false)}
                desktopPreview={getDesktopPreview()}
                publishLabel={getPreviewPublishLabel()}
                publishAction={publishConfirmed}
                isLoading={props.publishing || props.submitting}
                openAction={getPreviewOpenAction()}
                openLabel="VIEW PUBLISHED VERSION"
            />
            {getContentAnalysesConfirm()}
            {getBackToManagePostsConfirm()}
            {getDiscardPostConfirm()}
            {getRequireRevisionConfirm()}
            {getConfirmRepublishCompliancePost()}
        </>
    );
}

interface RouteParams {
    tenant: string;
    draftId: string;
}

interface ComponentProps {
    intl: IntlShape;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        currentUser: state.settings.currentUser,
        notificationSettings: state.settings.notificationSettings,
        tenantSettings: state.settings.tenantSettings,
        post: state.posts.editor.post,
        saving: state.posts.editor.saving,
        loading: state.posts.editor.fetching,
        publishing: state.posts.publishing,
        submitting: state.posts.submitting,
        changedSinceSaved: state.posts.editor.changedSinceSaved,
        publishValidationErrors: Object.keys(state.posts.publishValidationErrors.errors),
        lcidMappings: state.resources.lcidMappings,
        defaultLang: state.settings.tenantSettings ? state.settings.tenantSettings.defaultLCID : "en-us",
        isCompliance: !!state.posts.editor.post?.postType && state.posts.editor.post?.postType.toLowerCase() === "compliance",
        isValid: state.posts.editor.isValid,
        tagSettings: state.settings.tagSettings,
        tenantId: state.tenant.id
    }),
    {
        createNew: postActions.createNewDraftPost,
        clearFetchPost: postActions.clearFetchPost,
        save: postActions.saveDraft,
        redirectTo: push,
        fetchDraftPost: postActions.fetchDraftPost,
        getTagSettings: settingsActions.getTagSettings,
        publishPost: postActions.publishPost,
        submitPost: postActions.submitPost,
        publishSuccess: postActions.publishSuccess,
        setChangedSinceSaved: postActions.SetChangedSinceSaved,
        setSaving: postActions.SetSaving,
        setNewPost: postActions.setNewDraftPost,
        updateId: postActions.updateField("id"),
        updatePostContent: postActions.updateField("translatedContent"),
        saveUserSettings: userActions.saveUserSettings,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default injectIntl(withRouter(connector(PostCreation)));
