import * as actions from "network/actions";
import * as models from "modules/events/models";
import moment from "moment";
import { ApiRequest } from './network';
import { SparrowApi } from 'api/network/adapters/SparrowApi';
import cookie from "utils/cookie";
import { ImageScale } from "modules/posts/models";

export class EventsApi {


    constructor(private _sparrowApi: SparrowApi) {
    }

    private getId(): number {
        return 10000 * (Date.parse(new Date().toISOString()) - Date.parse(new Date(2001, 0, 1).toISOString()));
    }

    public SendEventClosedEvent = (eventId: string, lcid: string): ApiRequest => {
        const body = {
            Events: [{
                Name: "EventClosed",
                Platform: "Web",
                Time: new Date().toISOString(),
                EventId: eventId,
                MaxDepth: 1,
                ElapsedTime: 5,
                SessionId: this.getId().toString(),
                EventLanguage: lcid
            }]
        };

        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('{tenant}/api/v1/loggedevents', body)
        );
    }

    public SendEventOpenedEvent = (eventId: string, lcid: string): ApiRequest => {
        const body = {
            Events: [{
                Name: "EventOpened",
                Platform: "Web",
                Time: new Date().toISOString(),
                EventId: eventId,
                SessionId: this.getId().toString(),
                EventLanguage: lcid
            }]
        };

        return new ApiRequest(
            this._sparrowApi.TenantRequest().post('{tenant}/api/v1/loggedevents', body)
        );
    }
}

export const ChangeRSVPStatus = (id: string, attendeeIds: string[], attendanceType: models.AttendanceType) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/api/v1/events/${id}/managemulti?attendanceType=${attendanceType}`,
        body: JSON.stringify(attendeeIds)
    });
}

export const EditResponse = (id: string, attendeeId: string, attendanceType: models.AttendanceType, answers: models.Answers) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/api/v1/events/${id}/manage`,
        body: JSON.stringify({
            attendanceType,
            attendee: {
                id: attendeeId
            },
            answers
        })
    });
}

export const GetDrafts = (pageNumber: number, filters: Partial<models.EventFilterValues>, pageAmount?: number) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v1/drafts/events/paged?pageAmount=${rowsPerPage}&pageNumber=${pageNumber}`,
        body: JSON.stringify(filters)
    });
}

export const GetPublished = (pageNumber: number, filters: Partial<models.EventFilterValues>, pageAmount?: number) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v1/events/paged?pageAmount=${rowsPerPage}&pageNumber=${pageNumber}`,
        body: JSON.stringify({
            ...filters,
            olderThan: filters.olderThan ?? new Date().toISOString()
        })
    });
}

export const GetScheduled = (pageNumber: number, filters: Partial<models.EventFilterValues>, pageAmount?: number) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v1/events/paged?pageAmount=${rowsPerPage}&pageNumber=${pageNumber}`,
        body: JSON.stringify({
            ...filters,
            newerThan: filters.newerThan ?? new Date().toISOString()
        })
    });
}

export const GetAllEvents = (pageNumber: number, filters: Partial<models.EventFilterValues>, pageAmount?: number) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v1/allevents/paged?pageAmount=${rowsPerPage}&pageNumber=${pageNumber}`,
        body: JSON.stringify({
            ...filters,
        })
    });
}

export const GetAttendance = (id: string, attendanceTypes: models.AttendanceType[], pageNumber: number, filters: Partial<models.AttendanceFilterValues>) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/api/v1/events/${id}/attendance?pageNumber=${pageNumber}&pageAmount=10&includeAnswers=true`,
        body: JSON.stringify({
            ...filters,
            attendanceTypes: !!attendanceTypes.length ? attendanceTypes : null
        })
    });
}

export const GetAttendanceCSV = (id: string) => {
    return actions.NetworkRequest({
        method: "GET",
        url: `/{tenant}/api/v1/events/${id}/csv`
    });
}

export const GetContentAnalysis = (id: string, eventText: string) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v1/contentanalysis/${id}?platform=Web`,
        body: JSON.stringify({
            eventText
        })
    });
}

export const GetDraft = (draftId: string, imageScale: ImageScale = ImageScale.Unprocessed) => {
    return actions.NetworkRequest({
        method: "GET",
        url: `/{tenant}/adminapi/v1/drafts/events/${draftId}`,
        queryParams: {
            imageScale
        }
    });
}

export const GetDraftTranslation = (id: string, content: models.TranslatableEventContent, outputLCID: string) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/api/v1/cognitiveservices/translatortext/translate/${id}?bodyFormat=html&outputLCID=${outputLCID}&type=event&platform=Web`,
        body: JSON.stringify({
            ...content
        })
    });
}

export const GetEvent = (id: string, imageScale: ImageScale = ImageScale.Unprocessed) => {
    return actions.NetworkRequest({
        method: "GET",
        url: `/{tenant}/api/v1/events/${id}`,
        queryParams: {
            imageScale
        }
    });
}

export const GetEventFeed = (filters: Partial<models.EventFeedFilters>, sortAscending: boolean, pageNumber: number) => {
    const updatedFilters: Partial<models.EventFeedFilters> = {
        ...filters,
        eventTypes: !!filters.eventTypes && !!filters.eventTypes.length ? filters.eventTypes : undefined,
        tags: !!filters.tags && !!filters.tags.length ? filters.tags : undefined
    };

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/api/v1/events/feed?maxResults=12&pageNumber=${pageNumber}&sortAscending=${sortAscending}`,
        body: JSON.stringify(updatedFilters)
    });
}


export const CloneEvent = (id: string) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v1/drafts/events/${id}/clone`,
        body: JSON.stringify({})
    });
}

export const UnpublishEvent = (id: string) => {
    return actions.NetworkRequest({
        method: "DELETE",
        url: `/{tenant}/adminapi/v1/drafts/events/unpublish/${id}`
    });
}

export const DeleteEvent = (id: string) => {
    return actions.NetworkRequest({
        method: "DELETE",
        url: `/{tenant}/adminapi/v1/drafts/events/${id}`
    });
}

export const DeleteEventDraft = (id: string) => {
    return actions.NetworkRequest({
        method: "DELETE",
        url: `/{tenant}/adminapi/v1/drafts/events/delete/${id}`
    });
}

export const PublishEvent = (event: Partial<models.Event>, sendNotification: boolean) => {
    return actions.NetworkRequest({
        method: "POST",
        url: `/{tenant}/adminapi/v1/drafts/publishing/events/${event.id}?sendNotification=${sendNotification}`,
        body: JSON.stringify({}),
        headers: { "content-type": "application/json" }
    });
}

export const RSVP = (id: string, attendanceType: models.AttendanceType, answers: models.Answers) => {
    return actions.NetworkRequest({
        method: "POST",
        url: `/{tenant}/api/v1/events/${id}/rsvp`,
        body: JSON.stringify({ attendanceType, answers }),
        headers: { "content-type": "application/json" }
    });
}

export const SaveEvent = (event: Partial<models.Event>) => {
    const eventStartTime: string = Object.keys(event.eventTimes!)[0];

    const cutoffTime = event.respondingEnabled
        ? (event.cutoffTime || moment(eventStartTime).startOf("day").toISOString())
        : event.eventTimes![eventStartTime]
            ? moment(eventStartTime).startOf("day").toISOString()
            : eventStartTime;

    let translatedContent: models.TranslatedContent | undefined = event.translatedContent;
    for (let t in translatedContent) {
        let body: string = translatedContent[t].body;
        let html: HTMLDivElement = document.createElement("div");
        html.innerHTML = body;
        translatedContent[t] = {
            ...translatedContent[t],
            body
        };
    }

    const body = {
        attachedContent: event.attachedContent,
        author: event.author,
        authorEmail: event.authorEmail,
        bannerColor: event.bannerColor,
        capacity: event.capacity,
        cutoffTime: cutoffTime,
        eventTimes: event.eventTimes,
        eventType: event.eventType,
        expiryTime: event.expiryTime,
        fileAttachments: event.fileAttachments,
        id: event.id,
        imageId: !!event.image ? event.image.id : "",
        imageTransforms: !!event.image ? event.image.transforms : "",
        location: event.location,
        locationLink: event.locationLink,
        locationLinkLabel: event.locationLinkLabel,
        notifications: event.notifications,
        publishTime: event.publishTime,
        questions: event.questions,
        respondingEnabled: event.respondingEnabled,
        tags: event.tags ? event.tags.map((tag) => tag.id) : [],
        translatedContent: { ...event.translatedContent }
    };

    return actions.NetworkRequest({
        method: "POST",
        url: `/{tenant}/adminapi/v1/drafts/events/${event.id || "new"}`,
        body: JSON.stringify(body),
        headers: { "content-type": "application/json" }
    });
}
