import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import { UserRoles } from "modules/authorization/models";

import { IMenuItem, MenuItem } from "../../components/MenuItem";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
    DashboardOutlined,
    PostAddOutlined,
    LocalOfferOutlined,
    SendOutlined,
    MenuBookOutlined,
    AssessmentOutlined,
    HistoryOutlined,
    PhotoLibraryOutlined,
    AccountCircleOutlined,
    SettingsOutlined,
    OpenInNewOutlined,
    AdminPanelSettingsOutlined
} from "@mui/icons-material";
import { Button } from "@mui/material";
import UpdateOptionsDialog from "modules/updateOptions/updateOptionsDialog";
import useQueryParams, { QUERY_PARAM_KEYS } from "modules/common/hooks/useQueryParams";
import { useHistory, useLocation } from "react-router";
import { getHighestRole } from "utils/userRoleUtils";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            position: "static",
            overflow: "hidden auto"
        },
        poweredBy: {
            color: "#aaaaaa",
            fontSize: 10,
            marginBottom: 10,
            marginLeft: 23,
            marginTop: 10,
            opacity: 0,
            "&.expanded": {
                opacity: 1,
                transition: "opacity linear 0.1s 0.2s"
            }
        },
        updateOptions: {
            textTransform: "none",
            fontSize: 10,
            padding: 0,
            textDecoration: "underline",
            fontWeight: "normal",
            "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline"
            },
            color: "rgb(170, 170, 170)"
        },
        versionContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center"
        },
        pipe: {
            margin: "0 8px"
        }
    })
);

const menuItems = ({
    portalPagesEnabled,
    eventsEnabled,
    documentsEnabled,
    audiencesEnabled,
    currentUser,
    categoryTagsEnabled
}: PropsWithRedux): IMenuItem[] => {
    let items: IMenuItem[] = [
        {
            label: "Dashboard",
            type: "link",
            url: "~/admin/dashboard",
            icon: <DashboardOutlined />,
            userRole: UserRoles.Author
        },
        { label: "", type: "divider" },
        {
            label: "Content Management",
            type: "link",
            icon: <PostAddOutlined />,
            userRole: UserRoles.Author,
            children: [
                {
                    label: "Posts",
                    type: "link",
                    url: "~/admin/posts",
                    userRole: UserRoles.Author,
                },
                {
                    label: "Events",
                    type: "link",
                    url: "~/admin/events",
                    userRole: UserRoles.Author,
                    hide: !eventsEnabled,
                },
                {
                    label: "Surveys",
                    type: "link",
                    url: "~/admin/surveys",
                    userRole: UserRoles.Author,
                },
                {
                    label: "Documents",
                    type: "link",
                    url: "~/admin/documents",
                    userRole: UserRoles.Author,
                    hide: !documentsEnabled,
                },
                {
                    label: "Quick Links",
                    type: "link",
                    url: "~/admin/quickLinks",
                    userRole: UserRoles.Author,
                    hide: true,
                },
            ],
        },
        {
            label: "Targeting Tools",
            type: "link",
            icon: <LocalOfferOutlined />,
            userRole: UserRoles.Owner,
            children: [
                {
                    label: "Audiences",
                    type: "link",
                    url: "~/admin/audiences",
                    userRole: UserRoles.Owner,
                    hide:
                        !audiencesEnabled ||
                        !currentUser.roles.includes(UserRoles.Owner),
                },
                {
                    label: "Topics (Posts & Events)",
                    type: "link",
                    url: "~/admin/topics",
                    userRole: UserRoles.Owner,
                    hide: !currentUser.roles.includes(UserRoles.Owner),
                },
                {
                    label: "Category Tags (Others)",
                    type: "link",
                    url: "~/admin/categoryTags",
                    userRole: UserRoles.Owner,
                    hide:
                        !categoryTagsEnabled ||
                        !currentUser.roles.includes(UserRoles.Owner),
                },
            ],
        },
        {
            label: "Messaging Center",
            type: "link",
            icon: <SendOutlined />,
            userRole: UserRoles.Owner,
            children: [
                {
                    label: "Direct Messaging",
                    type: "link",
                    url: "~/admin/messaging",
                    userRole: UserRoles.Owner,
                },
                {
                    label: "Newsletters",
                    type: "link",
                    url: "~/admin/newsletters",
                    userRole: UserRoles.Owner,
                },
            ],
        },
        { label: "", type: "divider" },
        {
            label: "Portal Pages",
            type: "link",
            icon: <MenuBookOutlined />,
            url: "~/admin/portalPages",
            userRole: UserRoles.Owner,
            hide: !portalPagesEnabled,
        },
        {
            label: "Media Gallery",
            type: "link",
            icon: <PhotoLibraryOutlined />,
            url: "~/admin/gallery",
            userRole: UserRoles.Author,
        },
        {
            label: "Activity Tools",
            type: "link",
            icon: <HistoryOutlined />,
            url: "~/admin/activity",
            userRole: UserRoles.Owner,
        },
        {
            label: "Platform Analytics",
            type: "link",
            icon: <AssessmentOutlined />,
            url: "~/admin/insights",
            userRole: UserRoles.Author,
        },
        {
            label: "Users & Permissions",
            type: "link",
            icon: <AccountCircleOutlined />,
            url: "~/admin/users",
            userRole: UserRoles.Owner,
        },
        {
            label: "Tenant Configurations",
            type: "link",
            icon: <SettingsOutlined />,
            url: "~/admin/settings",
            userRole: UserRoles.Owner,
        },
        { label: "", type: "divider" },
        {
            label: "Tenant Management",
            type: "link",
            icon: <AdminPanelSettingsOutlined />,
            url: "~/admin/tenantManagement",
            userRole: UserRoles.SparrowSuperAdmin,
        },
        { label: "", type: "divider" },
        {
            label: "Go to Company Portal",
            icon: <OpenInNewOutlined />,
            type: "link",
            url: "~/home",
            pushToBrowserHistory: true,
        },
    ];

    return items;
};


const Menu: React.FunctionComponent<PropsWithRedux> = props => {
    const [expandedMenuItem, setExpandedMenuItem] = useState("");
    const [updateDialogOpen, setUpdateDialogOpen] = useState<boolean>(false);
    const classes = useStyles();
    const query = useQueryParams();
    const location = useLocation();
    const history = useHistory();

    const isContributor = getHighestRole(props.currentUser) === UserRoles.Contributor;

    // toggle state value of updateDialogOpen
    const toggleUpdateDialogOpen = () => {
        setUpdateDialogOpen((prev) => !prev);
    };

    // handle default update options modal behaviour
    useEffect(() => {
        const showUpdateOptions = query.get(QUERY_PARAM_KEYS.OPEN_UPDATE_OPTIONS);
        if (showUpdateOptions) {
            // remove the query param for next time component renders
            const queryParams = new URLSearchParams(location.search);
            queryParams.delete(QUERY_PARAM_KEYS.OPEN_UPDATE_OPTIONS);
            history.replace({ search: queryParams.toString() });

            setUpdateDialogOpen(showUpdateOptions === "true");
        }
    }, [query, history, location.search]);

    return (
        <>
            <Drawer
                open={props.expand}
                variant="permanent"
                PaperProps={{
                    elevation: 5
                }}
                classes={{
                    paper: props.drawerPaperClassName
                }}
            >
                <List className={classes.list}>
                    {menuItems(props).map((menuItem, index) => {
                        if (menuItem.type === "divider") {
                            return (
                                <div key={`contributor-divider-${index}`}>
                                    {
                                        !isContributor &&
                                        <Divider key={index} light/>
                                    }
                                </div>
                            );
                        } else {
                            let selected = false;
                            const menuItemUrl = menuItem.url || "";
                            if ((menuItem.url || "").startsWith("~/")) {
                                const menuItemUrlWithTenant = menuItemUrl.replace("~/", "/" + props.tenant + "/");
                                selected = props.locationPathName.indexOf(menuItemUrlWithTenant) >= 0;
                            } else if (!!menuItem.children && !!menuItem.children.length) {
                                selected = !!menuItem.children.find(subMenuItem => {
                                    const subMenuItemUrl = subMenuItem.url || "";
                                    const subMenuItemUrlWithTenant = subMenuItemUrl.replace("~/", "/" + props.tenant + "/");
                                    if (props.locationPathName.indexOf(subMenuItemUrlWithTenant) >= 0) {
                                        return true;
                                    } else if (!!subMenuItem.children && !!subMenuItem.children.length) {
                                        if (!!subMenuItem.children.find(childSubMenuItem => {
                                            const childSubMenuItemUrl = childSubMenuItem.url || "";
                                            const childSubMenuItemUrlWithTenant = childSubMenuItemUrl.replace("~/", "/" + props.tenant + "/");
                                            return props.locationPathName.indexOf(childSubMenuItemUrlWithTenant) >= 0;
                                        }))
                                            return true;
                                        else
                                            return false;
                                    } else {
                                        return false;
                                    }
                                });
                            }

                            return (
                                <MenuItem
                                    key={index}
                                    expand={props.expand}
                                    expandedMenuItem={expandedMenuItem}
                                    menuItem={menuItem}
                                    selected={selected}
                                    setExpand={props.setExpand}
                                    setExpandedMenuItem={setExpandedMenuItem}
                                />
                            );
                        }
                    })}
                </List>
                <div className={`${classes.poweredBy} ${props.expand ? "expanded" : ""}`}>
                    <div>Powered by Sparrow Connected</div>
                    <div className={classes.versionContainer}>
                        <span>{process.env.REACT_APP_ADMINPORTAL_VERSION}</span>
                        {
                            !isContributor &&
                            <>
                                <span className={classes.pipe}>|</span>
                                <Button
                                    variant="text"
                                    disableRipple
                                    className={`${classes.updateOptions}`}
                                    onClick={() => { toggleUpdateDialogOpen(); }}>
                                    Update Options
                                </Button>
                            </>

                        }
                    </div>
                </div>
            </Drawer>
            <UpdateOptionsDialog open={updateDialogOpen} onClose={toggleUpdateDialogOpen} />
        </>
    );
}

interface ComponentProps {
    expand?: boolean;
    locationPathName: string;
    setExpand: (expand: boolean) => void;
    tenant: string;
    drawerPaperClassName: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        audiencesEnabled: state.settings.tenantSettings.showFeatures.audiences,
        documentsEnabled: state.settings.tenantSettings.showFeatures.documentsEnabled && state.settings.tenantSettings.documentsEnabled,
        eventsEnabled: state.settings.tenantSettings.mobileEventsEnabled,
        categoryTagsEnabled: state.settings.tenantSettings.showFeatures.categoryTagsEnabled,
        portalPagesEnabled: state.settings.tenantSettings.showFeatures.portalPagesEnabled && state.settings.tenantSettings.portalPagesEnabled,
        currentUser: state.settings.currentUser
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(Menu);
