import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Prompt, RouteComponentProps } from "react-router-dom";
import * as actions from "../actionCreator";
import * as audiencesActions from "modules/audiences/actionCreator";
import * as settingsActions from "modules/settings/actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import moment from "moment";

import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";

import BasePage from "pages/common/basePage";
import Breadcrumb from "pages/common/breadcrumb";
import Columns from "pages/common/columns";
import MainContent from "pages/common/mainContent";

import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import "modules/common/components/authoring/authoring.sass";
import { Address, NewsletterDetails, NewsletterImageType, NewsletterTemplate, NewsletterTemplateImageType, NewsletterTheme, SaveNewsletterModelStateErrors } from "modules/newsletters";
import "../styles/newsletter.sass";
import "../styles/progress-bar.sass";
import Loading from "modules/common/components/loading";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { Audience } from "modules/audiences/models";
import Disclaimer from "modules/common/components/disclaimer";
import Link from "@mui/material/Link";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ColorOption from "./components/colorOption";
import OneColumnNewsletterPreview from "./components/oneColumnNewsletterPreview";
import ReplayIcon from "@mui/icons-material/Replay";
import UploaderWithLoad from "../common/uploader/uploaderWithLoad";
import { hexCodeFromText } from "utils/hexFunctions";

const colorLocations = ["emailBackground", "primaryBackground", "primaryText", "secondaryBackground", "secondaryText", "linkText"]
const headerType:NewsletterImageType = "Header";
const footerType:NewsletterImageType = "Footer";

class EditThemePage extends React.Component<PropsWithRedux, ComponentState> {

  constructor(props: PropsWithRedux) {
    super(props);

    const theme = this.getNewTheme();

    this.state = {
      currentTheme: theme,
      backupTheme: theme,
      emailBackgroundVisible: false,
      primaryBackgroundVisible: false,
      primaryTextVisible: false,
      secondaryBackgroundVisible: false,
      secondaryTextVisible: false,
      linkTextVisible: false,
      currentTitle: "",
      invalidState: false,
      imagesLoading: false,

      modelErrors: null,
      highlightTitleFields: false,
      highlightSubjectAndHeader: false,
      highlightSenderDetails: false,
      showTemplateSection: true,
      showAudienceFrequencySection: false,
      showNewsletterPreview: false,
      showContentSection: false,
      changedSinceSaved: false,
      isSaving: false,
      isLoading: false,
      selectedTab: 0,
      addresses: [],
      originalAddresses: [],
    };
  }

  componentDidMount() {
    moment.locale("en");

    this.setState({isLoading: true});
    this.props.getThemes().then((themes) => {
      if(!!themes){
        this.setState({themes: themes});

        // We have been passed a specific theme in the url.
        if(!!this.props.match.params.themeId){
          const themeFromUrl = themes.find(theme => theme.id === this.props.match.params.themeId);
          if(themeFromUrl)
          {
            let clonedTheme = JSON.parse(JSON.stringify(themeFromUrl));
            this.loadImages(themeFromUrl);
            this.setState({currentTheme: themeFromUrl, currentTitle: themeFromUrl.name, backupTheme: clonedTheme});
          }
          else{
            this.setState({errorMessage: "Could not load a theme for specified id.", invalidState: true});
          }
        }
      }
    });
    this.setState({isLoading: false});
  }

  private loadImages = async(theme: NewsletterTheme) => {
    this.setState({imagesLoading: true})
    if(theme.themeHeaderImageIsCustomized) {
      await this.props.getThemeImage(headerType, theme.id).then((result) => {
        this.setState({headerImage: result});
      })
    }

    if(theme.themeFooterImageIsCustomized) {
      await this.props.getThemeImage(footerType, theme.id).then((result) => {
        this.setState({footerImage: result});
      })
    }
    this.setState({imagesLoading: false});
  }

  public render() {
    const {currentTheme, backupTheme} = this.state;
    
    if(!!this.props.match.params.themeId && !this.state.currentTheme)
      return <Loading />;
    
    const formattedSavedOn = !this.state.invalidState && moment(new Date(this.state.currentTheme.createdOnUTC)).format("MMM D, YYYY, hh:mm a");
    const formattedModifiedOn = !this.state.invalidState && moment(new Date(this.state.currentTheme.lastModifiedOnUTC)).format("MMM D, YYYY, hh:mm a");

    return (
      <BasePage>
        <Prompt when={this.state.changedSinceSaved} message="You have unsaved changes, are you sure you want to leave this page?" />
        <Breadcrumb
          items={[
            { title: "Newsletters", onClick: this.backToManageNewsletters },
            { title: this.state.themes ? "Edit Theme" : "New" }
          ]}
          backItem={ {title: "Back to Manage Newsletters", onClick: this.backToManageNewsletters} }
          rightComponent={this.getCommands()}
        />
        <Columns
          leftComponent={
            <div className="newsletter-page">
              <MainContent addPadding10>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <span>Theme title<span className="required-coloring">*</span></span>
                  <TextField style={{margin: "5px"}} variant="outlined" size="small" value={this.state.currentTitle} onChange={(e) => this.setState({currentTitle: e.target.value})} placeholder="Enter theme title"/>
                </div>
              </MainContent>
              <MainContent addPadding10 addMarginTop>
              <Grid container spacing={1} style={{margin: '0', padding:'7px 0px', width: "95%"}}>
                <Grid item xs={6}>
                  Last modified on
                </Grid>
                <Grid item xs={6}>
                  <span style={{wordWrap: "break-word"}}>{this.state.currentTheme.lastModifiedOnUTC ? formattedModifiedOn : ""}</span>
                </Grid>

                <Grid item xs={6}>
                  by
                </Grid>
                <Grid item xs={6}>
                  <span style={{wordWrap: "break-word"}}>{currentTheme.lastModifiedByDisplayName}</span>
                </Grid>

                <Grid item xs={6}>
                  Saved on
                </Grid>
                <Grid item xs={6}>
                  <span style={{wordWrap: "break-word"}}>{formattedSavedOn}</span>
                </Grid>

                <Grid item xs={6}>
                  by
                </Grid>
                <Grid item xs={6}>
                  <span style={{wordWrap: "break-word"}}>{currentTheme.createdByDisplayName}</span>
                </Grid>
              </Grid>
              </MainContent>
            </div>
          }
          rightComponent={
            <div style={{overflowY: "scroll"}}>
              <div style={{backgroundColor: "#FFF", padding: "10px"}}>
                  <Disclaimer
                    icon={<InfoOutlinedIcon />}
                    text={
                      <React.Fragment>
                        <span>
                          Editing a theme does not modify newsletters that have already applied it. 
                          To apply an updated theme, edit the newsletter and go to 'Templates &amp; Themes' under the Template tab.&#160;
                          <Link onClick={() => window.open("https://support.sparrowconnected.com/en/how-do-i-use-a-newsletter-theme")} underline="always">Learn more</Link>.
                        </span>
                      </React.Fragment>
                    }
                  />

              {!this.state.invalidState && <div style={{marginTop: "20px"}}>
                <span style={{fontWeight: 500, fontSize: "16px"}}>Change theme images</span>

                <Grid container spacing={2} style={{marginTop: "20px"}}>
                  <Grid item xs={3}> 
                    Header image
                  </Grid>
                  <Grid item xs={9}>
                    <UploaderWithLoad
                      imageSrc={this.state.headerImage}
                      imageType="Header"
                      setImage={this.onChangeImage}
                      imageHeight={150} //Just manually setting as we never stray from 700x150
                      imageWidth={700}
                      onError={(err) => this.setState({errorMessage: err})}
                      isLoading={this.state.imagesLoading}
                    />
                  </Grid>

                  <Grid item xs={3}> 
                    Footer image
                  </Grid>
                  <Grid item xs={9}>
                    <UploaderWithLoad
                      imageSrc={this.state.footerImage}
                      imageType="Footer"
                      setImage={this.onChangeImage}
                      imageHeight={125} //Just manually setting as we never stray from 700x125
                      imageWidth={700}
                      onError={(err) => this.setState({errorMessage: err})}
                      isLoading={this.state.imagesLoading}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{paddingTop: "20px"}}>
                  <Grid item xs={6}>
                    <div>
                      <span style={{fontWeight: 500, fontSize: "16px", marginBottom: "20px"}}>Change theme colors</span>
                    </div>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <div style={{position: "relative", top: "6px"}}>Email background</div>
                      </Grid>
                      <Grid item xs={6}>
                        <ColorOption 
                          isColorPickerVisible={this.state.emailBackgroundVisible}
                          colorSelected={currentTheme.emailBackground}
                          setColor={(e) => this.changeNewsletterColor(e, colorLocations[0])}
                          changeText={(e) => this.changeNewsletterColor(e, colorLocations[0])}
                          setVisible={(e) => this.toggleColorVisible(e, colorLocations[0])}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {currentTheme && backupTheme.emailBackground !== currentTheme.emailBackground &&
                          <ReplayIcon 
                            style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}} 
                            onClick={() => this.changeNewsletterColor(backupTheme.emailBackground, colorLocations[0])}
                          />
                        }
                      </Grid>

                      <Grid item xs={5}>
                        <div style={{position: "relative", top: "6px"}}>Primary background</div>
                      </Grid>
                      <Grid item xs={6}>
                        <ColorOption 
                          isColorPickerVisible={this.state.primaryBackgroundVisible}
                          colorSelected={currentTheme.primaryBackground}
                          setColor={(e) => this.changeNewsletterColor(e, colorLocations[1])}
                          changeText={(e) => this.changeNewsletterColor(e, colorLocations[1])}
                          setVisible={(e) => this.toggleColorVisible(e, colorLocations[1])}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {currentTheme && backupTheme.primaryBackground !== currentTheme.primaryBackground &&
                          <ReplayIcon 
                            style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}} 
                            onClick={() => this.changeNewsletterColor(backupTheme.primaryBackground, colorLocations[1])}
                          />
                        }
                      </Grid>

                      <Grid item xs={5}>
                        <div style={{position: "relative", top: "6px"}}>Primary text</div>
                      </Grid>
                      <Grid item xs={6}>
                        <ColorOption 
                          isColorPickerVisible={this.state.primaryTextVisible}
                          colorSelected={currentTheme.primaryText}
                          setColor={(e) => this.changeNewsletterColor(e, colorLocations[2])}
                          changeText={(e) => this.changeNewsletterColor(e, colorLocations[2])}
                          setVisible={(e) => this.toggleColorVisible(e, colorLocations[2])}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {currentTheme && backupTheme.primaryText !== currentTheme.primaryText &&
                          <ReplayIcon 
                            style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}} 
                            onClick={() => this.changeNewsletterColor(backupTheme.primaryText, colorLocations[2])}
                          />
                        }
                      </Grid>
                      <Grid item xs={5}>
                        <div style={{position: "relative", top: "6px"}}>Secondary background</div>
                      </Grid>
                      <Grid item xs={6}>
                        <ColorOption 
                          isColorPickerVisible={this.state.secondaryBackgroundVisible}
                          colorSelected={currentTheme.secondaryBackground}
                          setColor={(e) => this.changeNewsletterColor(e, colorLocations[3])}
                          changeText={(e) => this.changeNewsletterColor(e, colorLocations[3])}
                          setVisible={(e) => this.toggleColorVisible(e, colorLocations[3])}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {currentTheme && backupTheme.secondaryBackground!== currentTheme.secondaryBackground &&
                          <ReplayIcon 
                            style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}} 
                            onClick={() => this.changeNewsletterColor(backupTheme.secondaryBackground, colorLocations[3])}
                          />
                        }
                      </Grid>

                      <Grid item xs={5}>
                        <div style={{position: "relative", top: "6px"}}>Secondary text</div>
                      </Grid>
                      <Grid item xs={6}>
                        <ColorOption 
                          isColorPickerVisible={this.state.secondaryTextVisible}
                          colorSelected={currentTheme.secondaryText}
                          setColor={(e) => this.changeNewsletterColor(e, colorLocations[4])}
                          changeText={(e) => this.changeNewsletterColor(e, colorLocations[4])}
                          setVisible={(e) => this.toggleColorVisible(e, colorLocations[4])}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {currentTheme && backupTheme.secondaryText !== currentTheme.secondaryText &&
                          <ReplayIcon 
                            style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}} 
                            onClick={() => this.changeNewsletterColor(backupTheme.secondaryText, colorLocations[4])}
                          />
                        }
                      </Grid>

                      <Grid item xs={5}>
                        <div style={{position: "relative", top: "6px"}}>Link text</div>
                      </Grid>
                      <Grid item xs={6}>
                        <ColorOption 
                          isColorPickerVisible={this.state.linkTextVisible}
                          colorSelected={currentTheme.linkText}
                          setColor={(e) => this.changeNewsletterColor(e, colorLocations[5])}
                          changeText={(e) => this.changeNewsletterColor(e, colorLocations[5])}
                          setVisible={(e) => this.toggleColorVisible(e, colorLocations[5])}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {currentTheme && backupTheme.linkText !== currentTheme.linkText &&
                          <ReplayIcon 
                            style={{fontSize: "20px", position: "relative", top: "7px", color: "#3B78AB"}} 
                            onClick={() => this.changeNewsletterColor(backupTheme.linkText, colorLocations[5])}
                          />
                        }
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <span style={{color: "#666666", fontStyle: "italic", fontSize: "11px" }}>This preview uses placeholder images and texts only.</span>
                    <OneColumnNewsletterPreview
                      emailBackground={currentTheme.emailBackground}
                      primaryBackground={currentTheme.primaryBackground}
                      primaryText={currentTheme.primaryText}
                      secondaryBackground={currentTheme.secondaryBackground}
                      secondaryText={currentTheme.secondaryText}
                      linkTexts={currentTheme.linkText}
                    />
                  </Grid>

                </Grid>
              </div>}

              </div>
            </div>
          }
        />
        <Dialog
          open={!!this.state.showUnsavedChangesDialogPriorTo}
          onClose={() => this.setState({ showUnsavedChangesDialogPriorTo: null })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">Back to Manage Newsletters</DialogTitle>
          <DialogContent>
            <span style={{fontWeight: "bold"}}>Your changes will not be saved.</span><br/>
            <span>Do you want to continue?</span>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={()=> {
                this.setState({ showUnsavedChangesDialogPriorTo: null });
                this.props.redirectTo("/" + this.props.tenant + "/admin/newsletters");
              }}
              autoFocus>
              Exit
            </Button>
            <Button onClick={() => this.setState({ showUnsavedChangesDialogPriorTo: null })} color="primary" variant="contained">
              Keep Editing
            </Button>
          </DialogActions>
        </Dialog>
        <LoadingOverlay absolute={true} show={this.state.isSaving || this.state.isLoading || false} />
        <SuccessSnackbar successMessage={this.state.successMessage || ""} clearSuccessMessage={() => this.setState({ successMessage: null })} />
        <ErrorSnackbar errorMessage={this.state.errorMessage || ""} clearErrorMessage={() => this.setState({ errorMessage: null })} />
      </BasePage>
    );
  }

  private onChangeImage = (imageType: NewsletterTemplateImageType, image?: File) => {
    switch(imageType)
    {
      case "Header":
          this.setState({headerImage: image, hasHeaderImageChanged: true});
        break;
      case "Footer":
        this.setState({footerImage: image, hasFooterImageChanged: true});
        break;
    }
  }

  private getCommands = (): JSX.Element => {
    const hasBeenChanged = this.hasBeenEdited();
    return (
      <React.Fragment>
        <Hidden mdDown>
          <Button 
            variant="contained" 
            disabled={this.state.invalidState || !hasBeenChanged}
            color="primary" 
            onClick={() => this.saveThemeAndUpdate()}
            >
              Save Changes
          </Button>
        </Hidden>
      </React.Fragment>
    );
  }

  private saveThemeAndUpdate = async() => {
    if(this.state.currentTheme.isCustom) {
      this.setState({isSaving: true});

      let headerImageEncoded = "";
      let deleteHeader = false;
      let footerImageEncoded = "";
      let deleteFooter = false;

      //Header exists, overwrite old one.
      if(!!this.state.headerImage && this.state.hasHeaderImageChanged) {
        headerImageEncoded = (await this.getBase64(this.state.headerImage)) ?? "";
      }
      //User deleted header, delete saved images.
      else if(!this.state.headerImage && this.state.currentTheme.themeHeaderImageIsCustomized) {
        deleteHeader = true;
      }
      
      if(!!this.state.footerImage && this.state.hasFooterImageChanged) {
        footerImageEncoded = (await this.getBase64(this.state.footerImage)) ?? "";
      }
      else if(!this.state.footerImage && this.state.currentTheme.themeFooterImageIsCustomized) {
        deleteFooter = true;
      }
      
      //There is an image to update
      if(headerImageEncoded !== "" || footerImageEncoded !== "")
        await this.props.saveThemeImages(this.state.currentTheme.id, headerImageEncoded, footerImageEncoded);
      
      if(deleteHeader) {
        await this.props.deleteThemeImage(headerType, this.state.currentTheme.id);
      }

      if(deleteFooter) {
        await this.props.deleteThemeImage(footerType, this.state.currentTheme.id);
      }

      const toSave = this.state.currentTheme;
      toSave.name = this.state.currentTitle;
      this.props.saveTheme(toSave).then(theme => {
        theme.isCustom = true;
        const themeClone = {...theme};
        this.setState({currentTheme: theme, backupTheme: themeClone, isSaving: false})
        if(theme) {
          this.setState({successMessage: "Theme changes have been saved."});
        }
        else {
          this.setState({errorMessage: "Theme could not be saved."})
        }
      }).catch(err => {
        this.setState({errorMessage: "Theme could not be saved."});
      });
    }
  }

  private getBase64 = file => {
    return new Promise<string>(resolve => {
      let baseURL:string = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result?.toString() ?? "";
        resolve(baseURL);
      };
    });
  };

  private getNewTheme = (): NewsletterTheme => {
    const theme: NewsletterTheme = {
      id: "guid.empty?",
      name: "",
      isDefault: false,
      isCustom: true,
      emailBackground: "#F5F5F5",
      primaryBackground: "#FEFEFA",
      primaryText: "#333333",
      secondaryBackground: "#949494",
      secondaryText: "#FFFFFF",
      linkText: "#3B78AB",
      createdBy: "", 
      createdByDisplayName: "",
      createdOnUTC: new Date(),
      lastModifiedBy: "",
      lastModifiedByDisplayName: "",
      lastModifiedOnUTC: new Date(),
      headerImage: "",
      footerImage: "",
      themeFooterImageIsCustomized: false,
      themeHeaderImageIsCustomized: false,
    }
    return theme;
  }

  private changeNewsletterColor = (toSet: string, toChange: string) => {
    let hexcode = hexCodeFromText(toSet);
    let isHexCodeValid = hexcode !== null;
    
    if(isHexCodeValid && hexcode) {
      let newTheme = this.state.currentTheme;
      switch(toChange){
        case colorLocations[0]:
          newTheme.emailBackground = hexcode;
          this.setState({currentTheme: newTheme})
          break;
        
        case colorLocations[1]:
          newTheme.primaryBackground = hexcode;
          this.setState({currentTheme: newTheme})
          break;
        
        case colorLocations[2]:
          newTheme.primaryText = hexcode;
          this.setState({currentTheme: newTheme})
          break;
        
        case colorLocations[3]:
          newTheme.secondaryBackground = hexcode;
          this.setState({currentTheme: newTheme})
          break;
        
        case colorLocations[4]:
          newTheme.secondaryText = hexcode;
          this.setState({currentTheme: newTheme})
          break;

        case colorLocations[5]:
          newTheme.linkText = hexcode;
          this.setState({currentTheme: newTheme})
          break;
      }
    }
  }

  private toggleColorVisible = (toSet: boolean, toChange: string) => {
    switch(toChange){
      case colorLocations[0]:
        this.setState({emailBackgroundVisible: toSet})
        break;
      
      case colorLocations[1]:
        this.setState({primaryBackgroundVisible: toSet})
        break;
      
      case colorLocations[2]:
        this.setState({primaryTextVisible: toSet})
        break;
      
      case colorLocations[3]:
        this.setState({secondaryBackgroundVisible: toSet})
        break;
      
      case colorLocations[4]:
        this.setState({secondaryTextVisible: toSet})
        break;

      case colorLocations[5]:
        this.setState({linkTextVisible: toSet})
        break;
    }
  }
  
  private backToManageNewsletters = () => {
    if(this.hasBeenEdited()) {
      this.setState({showUnsavedChangesDialogPriorTo: "onShowSendTestEmail"})
    }
    else {
      this.props.redirectTo("/" + this.props.tenant + "/admin/newsletters");
    }
  }

  private hasBeenEdited = () => {
    const {backupTheme, currentTheme} = this.state;

    if(backupTheme.primaryBackground !== currentTheme.primaryBackground 
      || currentTheme.emailBackground !== backupTheme.emailBackground
      || currentTheme.secondaryBackground !== backupTheme.secondaryBackground
      || currentTheme.primaryText !== backupTheme.primaryText 
      || currentTheme.secondaryText !== backupTheme.secondaryText
      || currentTheme.linkText !== backupTheme.linkText
      || backupTheme.name !== this.state.currentTitle
      || this.state.hasHeaderImageChanged
      || this.state.hasFooterImageChanged) {
        return true;
      }

    return false;
  }
}


interface RouteParams {
  themeId?: string;
}

interface ComponentState {
  currentTheme: NewsletterTheme;
  backupTheme: NewsletterTheme;
  emailBackgroundVisible: boolean;
  primaryBackgroundVisible: boolean;
  primaryTextVisible: boolean;
  secondaryBackgroundVisible: boolean;
  secondaryTextVisible: boolean;
  linkTextVisible: boolean;
  currentTitle: string;
  invalidState: boolean;
  imagesLoading: boolean;

  serverNewsletter?: NewsletterDetails;

  audiences?: Audience[];
  templates?: NewsletterTemplate[];
  themes?: NewsletterTheme[];
  selectedTemplate?: NewsletterTemplate;
  selectedTheme?: NewsletterTheme;

  modelErrors: SaveNewsletterModelStateErrors | null;
  successMessage?: string | null;
  errorMessage?: string | null;

  highlightTitleFields: boolean;
  highlightSubjectAndHeader: boolean;
  highlightSenderDetails: boolean;

  showTemplateSection: boolean;
  showAudienceFrequencySection: boolean;
  showNewsletterPreview?: boolean;
  showSendTestEmailDialog?: boolean;
  showUnsavedChangesDialogPriorTo?: 'onShowSendTestEmail' | null;
  showContentSection: boolean;

  headerImage?: File | null;
  isLoadingHeaderImage?: boolean;
  hasHeaderImageChanged?: boolean;

  footerImage?: File | null;
  isLoadingFooterImage?: boolean;
  hasFooterImageChanged?: boolean;

  changedSinceSaved:boolean;
  isSaving: boolean;
  isLoading: boolean;

  selectedTab: number;

  addresses: Address[];
  originalAddresses: Address[];
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: RouteComponentProps<RouteParams>) => ({
    ...ownProps,
    notificationSettings: state.settings.notificationSettings,
    tenant: state.tenant.id,
    tenantSettings: state.settings.tenantSettings,
    tagSettings: state.settings.tagSettings
  }),
  {
    getNewsletterDetails: actions.getNewsletterDetails,
    getUniqueUsers: audiencesActions.fetchUniqueUserCount,
    getNewsletterImage: actions.getNewsletterImage,
    saveNewsletter: actions.saveNewsletter,
    getAudiences: audiencesActions.getAudiences,
    getTemplates: actions.getTemplates,
    getThemes: actions.getThemes,
    getTemmplateHtml: actions.getTemplateHtml,
    getTemplateImage: actions.getTemplateImage,
    deleteNewsletterImage: actions.deleteNewsletterImage,
    uploadNewsletterImage: actions.uploadNewsletterImage,
    redirectTo: push,
    getTagSettings: settingsActions.getTagSettings,
    getConfig: actions.getConfig,
    saveTheme: actions.saveTheme,
    saveThemeImages: actions.saveThemeImages,
    getThemeImage: actions.getThemeImage,
    deleteThemeImage: actions.deleteThemeImage
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EditThemePage);